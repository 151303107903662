import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PlayingCard from "../../../../../components/context/playing-card/playingCard";
import { PickFormInputs } from "../pick";
import PickSubmitButton from "../pick-submit-button/pickSubmitButton";

export interface PickStaplesProps {
  morePicksNeeded: boolean;
  neededPickCount: number;
  instructionText: string;
}

const PickStaples = ({
  morePicksNeeded,
  neededPickCount,
  instructionText,
}: PickStaplesProps) => {
  const { t } = useTranslation("pick");
  const { watch, setValue } = useFormContext<PickFormInputs>();
  const cards = watch("cardPicks");
  const firstCardRef = useRef(cards[0]);
  const [focusedId, setFocusedId] = useState<number>(null);

  const unpickedCards = useMemo(
    () => cards.filter((c) => !c.selected),
    [cards]
  );

  const pickedCards = useMemo(() => cards.filter((c) => c.selected), [cards]);

  const resetFocusedId = useCallback(() => {
    setFocusedId(null);
  }, []);

  useEffect(() => {
    document.addEventListener("click", resetFocusedId);

    return () => document.removeEventListener("click", resetFocusedId);
  }, [resetFocusedId]);

  useEffect(
    function resetAfterPick() {
      setTimeout(() => setFocusedId(null), 100);
    },
    [pickedCards]
  );

  useEffect(function setInitialFocus() {
    setTimeout(() => setFocusedId(firstCardRef.current.id), 350);
  }, []);

  const focusedCardIndex = useMemo(
    () => cards.findIndex((c) => c.id === focusedId),
    [cards, focusedId]
  );

  const toggleCardSelected = useCallback(() => {
    const newCardPicks = [...cards];
    newCardPicks[focusedCardIndex].selected =
      !newCardPicks[focusedCardIndex].selected;
    setValue("cardPicks", newCardPicks);
  }, [cards, focusedCardIndex, setValue]);

  const getStyles = useCallback(
    (id: number, selected: boolean, index: number, side: "left" | "right") => {
      const isFocused = focusedId === id;
      const top = isFocused
        ? "50%"
        : `${(index / Math.max(cards.length, 12)) * 100}%`;

      const left = isFocused
        ? side === "left"
          ? "50%"
          : null
        : selected
        ? null
        : `0`;

      const right = isFocused
        ? side === "right"
          ? "50%"
          : null
        : selected
        ? "0"
        : null;

      const transform = isFocused
        ? `translate(${side === "left" ? -50 : 50}%, -50%)`
        : null;

      return {
        top,
        left,
        right,
        transform,
        boxShadow: isFocused
          ? "0px 0px 16px 4px rgba(0,0,0,0.29)"
          : "0px -1px 1px 1px rgba(0,0,0,0.29)",
        [side === "left" ? "marginLeft" : "marginRight"]:
          ((index % 3) - 1) * 2 + "px",
        zIndex: 100,
        transition: "all ease 450ms, transform ease 450ms",
      };
    },
    [cards, focusedId]
  );

  const pickRandom = useCallback(() => {
    let newCardPicks = [...cards];
    newCardPicks.forEach((c) => {
      c.selected = false;
    });
    newCardPicks = newCardPicks.sort(() => 0.5 - Math.random());
    for (let i = 0; i < neededPickCount; i++) {
      newCardPicks[i].selected = true;
    }

    setValue("cardPicks", newCardPicks);
  }, [cards, neededPickCount, setValue]);

  return (
    <div className="w-full h-full relative">
      <div data-name="unpickedCards" className="absolute inset-0">
        <div className="absolute left-0 rounded-md top-0 bottom-0 w-64 soso-bg">
          <div className="text-center w-full font-bold text-lg">
            {t("headlines.availableOptions")}
          </div>
        </div>

        {unpickedCards.map((c, i) => (
          <PlayingCard
            key={c.id}
            onClick={
              morePicksNeeded
                ? (evt, card) => {
                    evt.stopPropagation();
                    setFocusedId(card.id);
                  }
                : null
            }
            card={c}
            className={
              "absolute w-64 transition-all scale-75 hover:rotate-6 origin-bottom"
            }
            style={getStyles(c.id, c.selected, i, "left")}
            pickable={focusedId === c.id}
            picked={c.selected}
            onPickToggle={toggleCardSelected}
          />
        ))}
      </div>

      <div className="absolute right-0 rounded-md top-0 bottom-0 w-64 soso-bg">
        <div className="text-center w-full font-bold text-lg">
          {t("headlines.pickedOptions")}
        </div>
      </div>
      <div data-name="pickedCards" className="absolute inset-0">
        {pickedCards.map((c, i) => (
          <PlayingCard
            key={c.id}
            onClick={(evt, card) => {
              evt.stopPropagation();
              setFocusedId(card.id);
            }}
            card={c}
            className={
              "absolute w-64 transition-all scale-75 hover:-rotate-6 origin-bottom"
            }
            style={getStyles(c.id, c.selected, i, "right")}
            pickable={focusedId === c.id}
            picked={c.selected}
            onPickToggle={toggleCardSelected}
          />
        ))}
      </div>

      <div className="absolute top-4 left-1/2 flex items-end w-1/3 -translate-x-1/2 text-center text-lg">
        {instructionText}
      </div>

      <div className="absolute bottom-4 left-1/2 flex flex-col items-center gap-2 w-2/3 -translate-x-1/2">
        <PickSubmitButton
          morePicksNeeded={morePicksNeeded}
          neededPickCount={neededPickCount}
          pickedCardCount={pickedCards.length}
          className="w-1/2"
        />
        <button type="button" onClick={pickRandom}>
          {t("action.random")}
        </button>
      </div>
    </div>
  );
};

export default PickStaples;
