import { create } from "zustand";
import { Notification } from "../interfaces/notification";

export interface NotificationState {
  notifications: Notification[];

  sendNotification: (notification: Notification) => void;
  removeNotification: (notificationId: number) => void;
}

export const useNotificationStore = create<NotificationState>((set) => {
  const store: NotificationState = {
    notifications: [],
    removeNotification: (notificationId: number) =>
      set((state) => {
        const notification = state.notifications.find(
          (n) => n.id === notificationId
        );
        const index = state.notifications.indexOf(notification);
        const updatedNotifications = [...state.notifications];
        updatedNotifications.splice(index, 1);

        return {
          ...state,
          notifications: updatedNotifications,
        };
      }),
    sendNotification: (notification: Notification) =>
      set((state) => {
        notification.id = Date.now();
        setRemovalTimer(notification);
        return {
          ...state,
          notifications: [notification, ...state.notifications],
        };
      }),
  };

  const setRemovalTimer = (notification: Notification) => {
    const timeout = setTimeout(() => {
      store.removeNotification(notification.id);
      clearTimeout(timeout);
    }, notification.timeout || 5000);
  };

  return store;
});
