import { FC } from "react";
import { Game } from "../../../../../interfaces/game";

export interface GameScoreInterimProps {
  game: Game;
  className?: string;
}

const TeamScore = ({
  teamId,
  totalScore,
  className = "",
}: {
  teamId: number;
  totalScore: number;
  className?: string;
}) => {
  return (
    <div className={`text-center soso-bg p-2 ${className}`}>
      <div className="text-2xl font-bold">{totalScore}</div>
      <div className="text-md">Team {teamId}</div>
    </div>
  );
};

const GameScoreInterim: FC<GameScoreInterimProps> = ({ game, className }) => {
  const isSingleTeam = Object.keys(game.score.total).length === 1;

  return (
    <div
      className={`flex items-center justify-center gap-2 w-full rounded-md mx-auto ${className}`}
    >
      <TeamScore
        className="flex-1"
        teamId={1}
        totalScore={game.score.total[1]}
      />
      {!isSingleTeam && (
        <>
          <div className="text-2xl">:</div>
          <TeamScore
            className="flex-1"
            teamId={2}
            totalScore={game.score.total[2]}
          />
        </>
      )}
    </div>
  );
};

export default GameScoreInterim;
