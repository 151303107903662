import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/base/button";
import Card from "../../components/base/card";
import PageFrame from "../../components/layout/page-frame/pageFrame";
import { useSocket } from "../../hooks/useSocket.hook";
import { useSessionStore } from "../../store/session.store";

const ReconnectPage = () => {
  const { t } = useTranslation("game");
  const { handleSubmit } = useForm<{}>();
  const { reconnectToLobby } = useSocket();
  const { session } = useSessionStore();
  const { lobbyId } = useParams();

  const onSubmit: SubmitHandler<{}> = () => {
    reconnectToLobby(lobbyId, localStorage.getItem("soso_playerId"));
  };

  const navigate = useNavigate();
  const sessionId = useMemo(() => session?.id, [session]);

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    navigate(`/lobby/${sessionId}`);
  }, [navigate, sessionId]);

  return (
    <PageFrame>
      <Card className="max-w-[400px] w-full h-max px-12 py-8">
        <img
          src="/logo.png"
          alt="Name It! Logo"
          className="w-20 rounded-md mx-auto"
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Button type="submit" className="mt-6 w-full">
            {t("action.reconnect")}
          </Button>
        </form>
      </Card>
    </PageFrame>
  );
};

export default ReconnectPage;
