import { useTranslation } from "react-i18next";
import Button from "../../../../../components/base/button";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../../../store/session.store";
import GameModeRules from "../game-mode-rules/GameModeRules";

const GameStagePending = () => {
  const { t } = useTranslation("game");
  const { session } = useSessionStore();
  const { myTurn, activePlayer } = useSessionProps();
  const { startRound } = useSocket();

  return (
    <div className="block justify-center text-center max-w-[480px] w-full">
      <GameModeRules mode={session.game.currentMode} />
      {myTurn ? (
        <Button className="w-max" onClick={startRound}>
          {t("action.startRound")}
        </Button>
      ) : (
        <div>{t("hints.waitingForPlayer", { player: activePlayer.name })}</div>
      )}
    </div>
  );
};

export default GameStagePending;
