import Button, { ButtonProps } from "./button";

export type IconButtonProps = {
  ref?: React.Ref<HTMLButtonElement>;
  children: React.ReactNode;
} & ButtonProps;

const IconButton = ({
  className,
  children,
  ref,
  ...props
}: IconButtonProps) => {
  return (
    <Button
      ref={ref}
      className={`flex justify-center items-center w-10 aspect-square ${
        className?.includes("rounded-") ? "" : "rounded-full"
      } ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default IconButton;
