const GameLocalesDE = {
  stats: {
    cardsInDeck: "Karten im Deck",
  },
  action: {
    startRound: "Runde starten",
    startNextRound: "Nächste Runde starten",
    solved: "Gelöst!",
    skip: "Überspringen",
    reconnect: "Wieder verbinden",
  },
  hints: {
    cardBeingDealt: "Karte wird ausgeteilt...",
    waitingForPlayer: "{{player}} ist an der Reihe!",
    opposingPlayerIsActive: "{{player}} ist dran!",
    teamPlayerIsActive: "{{player}} ist dran! Rate mit!",
    skipped: "Übersprungen...",
    guessed: "Richtig!",
    lastCard: "Letzte Karte",
  },
  score: {
    total: "Summe",
  },
  mode: {
    EXPLAIN: "Erklären",
    WORD: "Ein Wort",
    DRAW: "Malen",
  },
  modeRules: {
    EXPLAIN:
      "Es ist so einfach, wie es klingt: Erkläre den Begriff!\n\nNatürlich sollte der Begriff selbst nicht Teil der Erklärung sein. Ansonsten sind deiner Fantasie keine Grenzen gesetzt!",
    WORD: "Ein Wort ist vorgegeben, eins darfst du selbst wählen!\n\nBeschreibe den Begriff mit genau einem anderen Begriff. Steht dein Team auf dem Schlauch? Dann kannst du immer noch überspringen und dich am nächsten Wort versuchen!",
    DRAW: "Ein Bild sagt mehr als tausend Worte!\n\nIn dieser Runde lässt du ein Bild statt deiner Stimme sprechen.",
  },
};

export default GameLocalesDE;
