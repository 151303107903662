import { RiCloseFill } from "react-icons/ri";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../../../store/session.store";

const GameTeams = () => {
  const { session } = useSessionStore();
  const { activePlayer, isAdmin, me } = useSessionProps();
  const { kickPlayer } = useSocket();

  return (
    <div className="flex flex-col gap-2 py-2 overflow-y-hidden h-full">
      <div className="flex gap-2">
        {Object.keys(session.game.teams).map((team: string) => {
          return (
            <div
              key={`heading_${team}`}
              className="text-center text-md font-bold flex-1"
            >
              Team {team}
            </div>
          );
        })}
      </div>
      <div className="flex gap-2 flex-1 min-h-0 overflow-y-auto flex-nowrap">
        {Object.keys(session.game.teams).map((team: string) => {
          return (
            <ul key={team} className="flex-1 min-w-0 w-full">
              {session.game.teams[+team].players.map((p) => {
                const isActive = activePlayer?.id === p?.id;
                const isMe = me?.id === p?.id;
                return (
                  <li
                    className={`pl-4 pr-4 py-2 mb-2 flex items-center justify-between ${
                      isActive ? "bg-white/20" : "soso-bg"
                    } rounded-md font-bold`}
                    key={p.id}
                    style={{
                      color: p.color,
                    }}
                  >
                    <span
                      className="flex-1 min-w-0 overflow-hidden text-ellipsis"
                      title={p.name}
                    >
                      {p.name}
                    </span>
                    {isAdmin && !isMe && (
                      <button
                        className="flex-initial"
                        onClick={() => kickPlayer(p.id)}
                      >
                        <RiCloseFill size={20} />
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </div>
    </div>
  );
};

export default GameTeams;
