import { useTranslation } from "react-i18next";
import Button from "../../../../../components/base/button";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../../../store/session.store";
import GameModeRules from "../game-mode-rules/GameModeRules";
import GameScoreInterim from "../game-score-interim/GameScoreInterim";

const GameStageResults = () => {
  const { t } = useTranslation("game");
  const { session } = useSessionStore();
  const { myTurn, activePlayer } = useSessionProps();
  const { startRound } = useSocket();

  return (
    <div className="block text-center max-w-[480px] w-full">
      <GameScoreInterim className="mb-2 md:mb-4" game={session.game} />
      <GameModeRules mode={session.game.currentMode} />

      {myTurn ? (
        <Button onClick={startRound}>{t("action.startNextRound")}</Button>
      ) : (
        <div className="w-full soso-bg p-4 rounded-md">
          {t("hints.waitingForPlayer", { player: activePlayer.name })}
        </div>
      )}
    </div>
  );
};

export default GameStageResults;
