import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonLevel } from "../../../../components/base/button";
import { useSessionProps } from "../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../../store/session.store";
import GameScore from "../game-state/game-score/GameScore";

const Results = () => {
  const { t } = useTranslation("results");
  const { session } = useSessionStore();
  const { nextLobbyState } = useSocket();
  const { isAdmin } = useSessionProps();
  const winnerTeams = useMemo(() => {
    const maxScore = Math.max(...Object.values(session.game.score.total));

    return Object.keys(session.game.score.total).filter(
      (team) => session.game.score.total[+team] === maxScore
    );
  }, [session]);

  const text = useMemo(() => {
    if (winnerTeams.length === 2) {
      return "DRAW";
    }

    return `Team ${winnerTeams[0]} wins!`;
  }, [winnerTeams]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="text-5xl md:text-6xl mb-12">{text}</div>
      <div className="w-full md:w-1/2">
        <GameScore game={session.game} />
      </div>
      {isAdmin && (
        <Button
          className="mt-12 px-8"
          level={ButtonLevel.POSITIVE}
          onClick={nextLobbyState}
        >
          {t("action.restart")}
        </Button>
      )}
    </div>
  );
};

export default Results;
