import FloodFill from "q-floodfill";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IconType } from "react-icons";
import { RiCloseFill } from "react-icons/ri";
import {
  TbBrush,
  TbBucketDroplet,
  TbCircle,
  TbCircleFilled,
  TbLine,
  TbPencil,
  TbRectangle,
} from "react-icons/tb";
import { useSocket } from "../../../../../hooks/useSocket.hook";
import ColorButton from "./ColorButton";

interface GameCanvasProps {
  cardId: number;
}

function drawEllipse(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) {
  context.ellipse(
    x,
    y,
    Math.abs(width / 2),
    Math.abs(height / 2),
    0,
    0,
    2 * Math.PI
  );
}

function floodFill(
  canvas: HTMLCanvasElement,
  fillColor: string,
  x: number,
  y: number
) {
  const context = canvas.getContext("2d");
  // get image data
  const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
  // Construct flood fill instance
  const floodFill = new FloodFill(imgData);
  // Modify image data
  floodFill.fill(fillColor, Math.floor(x), Math.floor(y), 32);
  // put the modified data back in context
  context.putImageData(floodFill.imageData, 0, 0);
}

const colorsColumns: string[][] = [
  // Bright colors
  [
    "#000000",
    "#FFFFFF",
    "#FF4136",
    "#FF851B",
    "#FFDC00",
    "#FFFF00",
    "#01FF70",
    "#2ECC40",
    "#7FDBFF",
    "#0074D9",
    "#B10DC9",
    "#F012BE",
  ],
  // Dark colors
  [
    "#444444",
    "#BEBEBE",
    "#A80017",
    "#9B4E00",
    "#BF8F00",
    "#AAAA00",
    "#008E42",
    "#1E8449",
    "#00B5B5",
    "#0A2C6D",
    "#6A006A",
    "#9A0D50",
  ],
];

function drawPoints(
  ctx: CanvasRenderingContext2D,
  points: { x: number; y: number }[]
) {
  // draw a basic circle instead
  if (points.length < 6) {
    var b = points[0];
    ctx.beginPath();
    ctx.arc(b.x, b.y, ctx.lineWidth / 2, 0, Math.PI * 2, !0);
    ctx.closePath();
    ctx.fill();
    return;
  }
  ctx.beginPath();
  ctx.moveTo(points[0].x, points[0].y);
  // draw a bunch of quadratics, using the average of two points as the control point
  let i;
  for (i = 1; i < points.length - 2; i++) {
    var c = (points[i].x + points[i + 1].x) / 2,
      d = (points[i].y + points[i + 1].y) / 2;
    ctx.quadraticCurveTo(points[i].x, points[i].y, c, d);
  }
  ctx.quadraticCurveTo(
    points[i].x,
    points[i].y,
    points[i + 1].x,
    points[i + 1].y
  );
  ctx.stroke();
}

const GameCanvas: React.FC<GameCanvasProps> = ({ cardId }) => {
  const pointsRef = useRef<{ x: number; y: number }[]>([]);
  const { drawImage } = useSocket();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D>(null);
  const drawingRef = useRef<boolean>(false);
  const initialCoordsRef = useRef<{ x: number; y: number }>(null);
  const [currentCoords, setCurrentCoords] = useState<{ x: number; y: number }>(
    null
  );
  const [tool, setTool] = useState<
    "brush" | "pen" | "fill" | "eraser" | "shape"
  >("brush");
  const [color, setColor] = useState<string>("#000000");
  const [shape, setShape] = useState<"rectangle" | "circle" | "line">(
    "rectangle"
  );
  const [lineWidth, setLineWidth] = useState<number>(6);
  const lineWidthRef = useRef<number>(lineWidth);
  lineWidthRef.current = lineWidth;

  const colorRef = useRef<string>(color);
  colorRef.current = color;

  const shapeRef = useRef<string>(shape);
  shapeRef.current = shape;

  const toolRef = useRef<string>(tool);
  toolRef.current = tool;

  const imageDataRef = useRef<ImageData | null>(null);

  const getRelativeCoordinates = (event: MouseEvent | TouchEvent) => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const rect = canvas.getBoundingClientRect();
    const x = "clientX" in event ? event.clientX : event.touches[0].clientX;
    const y = "clientY" in event ? event.clientY : event.touches[0].clientY;
    return {
      x: x - rect.left,
      y: y - rect.top,
    };
  };

  const handleClear = useCallback(() => {
    if (!contextRef.current || canvasRef.current.width === 0) {
      return;
    }

    contextRef.current.clearRect(
      0,
      0,
      contextRef.current.canvas.width,
      contextRef.current.canvas.height
    );
    contextRef.current.fillStyle = "#fff";
    contextRef.current.fillRect(
      0,
      0,
      contextRef.current.canvas.width,
      contextRef.current.canvas.height
    );
    imageDataRef.current = contextRef.current.getImageData(
      0,
      0,
      contextRef.current.canvas.width,
      contextRef.current.canvas.height
    );
    contextRef.current.beginPath();
    const dataUrl = canvasRef.current.toDataURL("image/png");
    drawImage(dataUrl);
  }, [drawImage]);

  useEffect(() => {
    handleClear();
  }, [handleClear, cardId]);

  const handleFill = useCallback((x: number, y: number) => {
    if (toolRef.current === "fill" && contextRef.current) {
      floodFill(canvasRef.current, colorRef.current, x, y);
    }
  }, []);

  useEffect(() => {
    if (contextRef.current) {
      if (toolRef.current === "eraser") {
        contextRef.current.strokeStyle = "white";
        contextRef.current.lineWidth = 20;
      } else {
        contextRef.current.strokeStyle = color || "#0000000";
        lineWidthRef.current = toolRef.current === "pen" ? 1 : lineWidth;
        contextRef.current.lineWidth = lineWidthRef.current;
      }
    }
  }, [lineWidth, color]);

  const handleStart = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const { x, y } = getRelativeCoordinates(event);
      initialCoordsRef.current = { x, y };
      pointsRef.current = [];
      contextRef.current.lineWidth = lineWidthRef.current;

      if (toolRef.current === "fill") {
        handleFill(x, y);
      } else {
        drawingRef.current = true;
        contextRef.current?.moveTo(x, y);
        contextRef.current?.beginPath();
      }
    },
    [handleFill]
  );

  const handleMove = useCallback((event: MouseEvent | TouchEvent) => {
    const { x, y } = getRelativeCoordinates(event);
    setCurrentCoords({ x, y });
    if (!drawingRef.current || toolRef.current === "fill") return;
    const ctx = contextRef.current;

    if (toolRef.current === "shape" && initialCoordsRef.current) {
      // Clear the previous temporary shape
      ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Redraw the previous image
      if (imageDataRef.current) {
        ctx?.putImageData(imageDataRef.current, 0, 0);
      }

      ctx?.beginPath();
      ctx.strokeStyle = colorRef.current;
      ctx.fillStyle = colorRef.current;

      switch (shapeRef.current) {
        case "rectangle":
          ctx?.rect(
            initialCoordsRef.current.x,
            initialCoordsRef.current.y,
            x - initialCoordsRef.current.x,
            y - initialCoordsRef.current.y
          );
          ctx?.stroke();
          break;
        case "circle":
          const radiusX = x - initialCoordsRef.current.x;
          const radiusY = y - initialCoordsRef.current.y;
          drawEllipse(
            ctx,
            initialCoordsRef.current.x + radiusX / 2,
            initialCoordsRef.current.y + radiusY / 2,
            radiusX,
            radiusY
          );
          ctx?.stroke();
          break;
        case "line":
          ctx?.moveTo(initialCoordsRef.current.x, initialCoordsRef.current.y);
          ctx?.lineTo(x, y);
          ctx?.stroke();
          break;
      }
    } else {
      /*
      ctx.strokeStyle = colorRef.current;
      ctx.fillStyle = colorRef.current;
      ctx?.lineTo(x, y);
      ctx?.stroke();
      */

      // Clear the previous temporary shape
      ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Redraw the previous image
      if (imageDataRef.current) {
        ctx?.putImageData(imageDataRef.current, 0, 0);
      }
      pointsRef.current.push({
        x,
        y,
      });
      drawPoints(ctx, pointsRef.current);
    }
  }, []);

  const handleEnd = useCallback(
    (event: MouseEvent | TouchEvent) => {
      drawingRef.current = false;
      initialCoordsRef.current = null;
      const ctx = contextRef.current;
      imageDataRef.current = ctx?.getImageData(
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
      const dataUrl = canvasRef.current.toDataURL("image/png");
      drawImage(dataUrl);
      pointsRef.current = [];
    },
    [drawImage]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      contextRef.current = ctx;

      ctx.lineCap = "round";
      ctx.lineJoin = "round";

      const updateCanvasSize = () => {
        const parent = canvas?.parentElement;
        const parentRect = parent?.getBoundingClientRect();
        canvas.width = parentRect?.width || 0;
        canvas.height = parentRect?.height || 0;
        if (imageDataRef.current) {
          ctx?.putImageData(imageDataRef.current, 0, 0);
        }
      };

      updateCanvasSize();
      handleClear();

      canvas.addEventListener("mousedown", handleStart);
      canvas.addEventListener("mousemove", handleMove);
      canvas.addEventListener("mouseup", handleEnd);
      canvas.addEventListener("mouseleave", handleEnd);
      canvas.addEventListener("touchstart", handleStart);
      canvas.addEventListener("touchmove", handleMove);
      canvas.addEventListener("touchend", handleEnd);
      canvas.addEventListener("touchcancel", handleEnd);

      window.addEventListener("resize", updateCanvasSize);

      return () => {
        canvas.removeEventListener("mousedown", handleStart);
        canvas.removeEventListener("mousemove", handleMove);
        canvas.removeEventListener("mouseup", handleEnd);
        canvas.removeEventListener("mouseleave", handleEnd);
        canvas.removeEventListener("touchstart", handleStart);
        canvas.removeEventListener("touchmove", handleMove);
        canvas.removeEventListener("touchend", handleEnd);
        canvas.removeEventListener("touchcancel", handleEnd);
        window.removeEventListener("resize", updateCanvasSize);
      };
    }
  }, [handleClear, handleEnd, handleMove, handleStart]);

  const ToolIcon: IconType = useMemo(() => {
    switch (tool) {
      case "brush":
        return TbBrush;
      case "pen":
        return TbPencil;
      case "fill":
        return TbBucketDroplet;
      case "shape":
        switch (shape) {
          case "circle":
            return TbCircle;
          case "line":
            return TbLine;
          case "rectangle":
            return TbRectangle;
        }
    }
  }, [shape, tool]);

  return (
    <div className="flex bg-white w-full h-full rounded-lg overflow-hidden relative">
      {currentCoords && (
        <div
          className={`absolute 0 0 text-black z-50 pointer-events-none`}
          style={{
            transform: `translate(${currentCoords.x}px, ${currentCoords.y}px)`,
            color,
          }}
        >
          <ToolIcon size={12} className="absolute -top-3 left-0" />
          <div
            className={`absolute border rounded-full shadow-sm`}
            style={{
              width: lineWidth + 1,
              height: lineWidth + 1,
              background: color,
              top: lineWidth / -2 + "px",
              left: lineWidth / -2 + "px",
            }}
          />
        </div>
      )}
      <div className="flex-1 cursor-none min-w-0">
        <canvas
          className="w-full h-full"
          ref={canvasRef}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            background: "#fff",
            touchAction: "none",
          }}
        />
      </div>
      {colorsColumns.map((colorColumn, i) => {
        return (
          <div className="flex flex-col z-50 relative" key={"col" + i}>
            {colorColumn.map((hexCol) => {
              return (
                <ColorButton key={hexCol} color={hexCol} setColor={setColor} />
              );
            })}
          </div>
        );
      })}
      <div className="flex flex-col text-black z-50 relative">
        <button className="block w-6 h-6" onClick={() => setTool("pen")}>
          <TbPencil size={24} />
        </button>
        <button className="block w-6 h-6" onClick={() => setTool("brush")}>
          <TbBrush size={24} />
        </button>
        <button
          className="block w-6 h-6"
          onClick={() => {
            setTool("shape");
            setShape("rectangle");
          }}
        >
          <TbRectangle size={24} />
        </button>
        <button
          className="block w-6 h-6"
          onClick={() => {
            setTool("shape");
            setShape("circle");
          }}
        >
          <TbCircle size={24} />
        </button>
        <button
          className="block w-6 h-6"
          onClick={() => {
            setTool("shape");
            setShape("line");
          }}
        >
          <TbLine size={24} />
        </button>
        <button
          className="block w-6 h-6"
          onClick={() => {
            setTool("fill");
          }}
        >
          <TbBucketDroplet size={24} />
        </button>
        <button className="block w-6 h-6" onClick={() => handleClear()}>
          <RiCloseFill size={24} />
        </button>

        <div className="w-6 h-6"></div>
        <div className="w-6 h-6"></div>
        <div className="w-6 h-6"></div>
        <div className="w-6 h-6"></div>
        <div className="w-6 h-6"></div>

        <button
          className="w-6 h-6 flex items-center justify-center"
          onClick={() => setLineWidth(6)}
        >
          <TbCircleFilled size={12} />
        </button>
        <button
          className="w-6 h-6 flex items-center justify-center"
          onClick={() => setLineWidth(10)}
        >
          <TbCircleFilled size={20} />
        </button>
        <button
          className="w-6 h-6 flex items-center justify-center"
          onClick={() => setLineWidth(14)}
        >
          <TbCircleFilled size={28} />
        </button>
      </div>
    </div>
  );
};

export default GameCanvas;
