import { useTranslation } from "react-i18next";
import { Game } from "../../../../../interfaces/game";

export interface GameScoreProps {
  game: Game;
}

const GameScore = ({ game }: GameScoreProps) => {
  const { t } = useTranslation("game");

  return (
    <table className="min-w-full text-left">
      <thead className="border-b font-medium dark:border-white/10">
        <tr>
          <th className="py-2" />
          {game.modes.map((mode) => (
            <th key={mode} className="py-2">
              {t(`mode.${mode}`)}
            </th>
          ))}
          <th className="py-2">{t("score.total")}</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(game.teams).map((team) => {
          return (
            <tr
              key={team}
              className="border-b font-medium dark:border-white/10"
            >
              <th scope="col" className="py-2">
                Team {team}
              </th>
              {game.modes.map((mode) => (
                <td key={team + "_" + mode} className="py-2">
                  {game.score.byMode[mode][+team]}
                </td>
              ))}
              <td className="py-2">{game.score.total[+team]}</td>
            </tr>
          );
        })}
        <tr></tr>
      </tbody>
    </table>
  );
};

export default GameScore;
