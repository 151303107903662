import moment from "moment";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSessionStore } from "../../../../../store/session.store";
import GameConfigMode from "../../../game-config/game-config-mode/gameConfigMode";
import CountdownTimer from "../countdown/CountdownTimer";
import RemainingDeck from "../remaining-deck/RemainingDeck";

export interface GameHeaderProps {
  className?: string;
}

const GameHeader: FC<GameHeaderProps> = ({ className }) => {
  const { t } = useTranslation("game");
  const { session } = useSessionStore();
  const { currentMode, activePlayer, myTeamsTurn } = useSessionProps();
  const timerEnd = session.game.timerEnd;
  const timerEndDate = useMemo(() => {
    if (!timerEnd) {
      return null;
    }

    return moment(timerEnd).toDate();
  }, [timerEnd]);

  return (
    <div className={`flex w-full gap-1 ${className}`}>
      <GameConfigMode className="w-8 h-8" mode={currentMode} hideText />
      <RemainingDeck className="soso-bg" />
      <div className="flex-1 soso-bg flex items-center justify-center text-sm">
        {myTeamsTurn
          ? t("hints.teamPlayerIsActive", { player: activePlayer.name })
          : t("hints.opposingPlayerIsActive", { player: activePlayer.name })}
      </div>
      <CountdownTimer
        className="aspect-square w-8 h-8"
        targetDate={timerEndDate}
      />
    </div>
  );
};

export default GameHeader;
