export interface CardProps {
  children: React.ReactNode;
  size?: "lg" | "xl" | "xxl";
  className?: string;
}

const Card = ({ children, className = "", size = "lg" }: CardProps) => {
  return (
    <div
      className={`relative mx-auto bg-white/70 dark:bg-black/70 w-full p-2 md:p-4 rounded-xl min-h-0 drop-shadow-md border-4 border-white ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
