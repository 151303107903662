import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useCountdown } from "./use-countdown/useCountdown.hook";

export interface CountdownTimerProps {
  targetDate: Date;
  className?: string;
}

const CountdownTimer = ({ targetDate, className }: CountdownTimerProps) => {
  const [startedDate, setStartedDate] = useState<Date>(null);
  useEffect(() => {
    setStartedDate(new Date());
  }, [targetDate]);

  const [days, hours, minutes, seconds] = useCountdown(
    targetDate || new Date()
  );
  const expired = days + hours + minutes + seconds <= 0;

  const startToEndSeconds = useMemo(() => {
    if (expired || !targetDate || !startedDate) {
      return -1;
    }

    return moment(targetDate).diff(startedDate, "s");
  }, [expired, startedDate, targetDate]);

  const countDownSeconds = useMemo(() => {
    if (expired || !targetDate) {
      return -1;
    }

    return seconds + minutes * 60;
  }, [expired, minutes, seconds, targetDate]);

  const countDownContent = useMemo(() => {
    if (expired || !targetDate) {
      return "-";
    }

    return countDownSeconds;
  }, [countDownSeconds, expired, targetDate]);

  return (
    <div
      className={`relative text-md md:text-4xl flex items-center justify-center aspect-square soso-bg ${className}`}
    >
      {countDownContent}
      <svg
        className="absolute inset-0 md:inset-4"
        viewBox="0 0 100 100"
        style={{
          transform: "rotateZ(-90deg) rotateX(-180deg)",
        }}
      >
        <circle
          r="44"
          cx="50"
          cy="50"
          strokeDasharray="332px"
          strokeLinecap="round"
          strokeWidth="6px"
          stroke="white"
          fill="none"
          style={{
            transformOrigin: "center center",
            transition: "all linear 1000ms",
          }}
          strokeDashoffset={`${
            expired || !targetDate
              ? -277
              : -(277 * (startToEndSeconds - countDownSeconds)) /
                startToEndSeconds
          }px`}
        ></circle>
      </svg>
    </div>
  );
};

export default CountdownTimer;
