import { useEffect, useMemo } from "react";
import { GameMode, GamePhase, Team } from "../interfaces/game";
import { Player } from "../interfaces/player";
import { useSessionStore } from "../store/session.store";

interface UseSessionPropsResult {
  isAdmin: boolean;
  me: Player;
  activePlayer: Player;
  activeTeam: Team;
  myTurn: boolean;
  myTeamsTurn: boolean;
  currentMode: GameMode;
  currentPhase: GamePhase;
}

export function useSessionProps(): UseSessionPropsResult {
  const { ownId, session, setLastGuessedCard, setLastSkippedCard } =
    useSessionStore();

  const currentGamePhase = useMemo(
    () => session?.game?.currentPhase,
    [session]
  );
  useEffect(
    function resetOnGamePhaseChange() {
      setLastGuessedCard({ cardId: null });
      setLastSkippedCard({ cardId: null });
    },
    [setLastGuessedCard, setLastSkippedCard, currentGamePhase]
  );

  const isAdmin = useMemo(() => ownId === session?.owner.id, [session, ownId]);

  const me = useMemo(
    () => session?.players.find((p) => p.id === ownId),
    [session, ownId]
  );

  const activeTeam = useMemo(
    () => session?.game.teams[session?.game.currentTeamId],
    [session?.game.teams, session?.game.currentTeamId]
  );

  const activePlayer = useMemo(
    () =>
      activeTeam?.players[activeTeam?.currentPlayerIndex] || {
        id: null,
        color: null,
        connected: false,
        hasPicked: false,
        name: null,
        team: null,
      },
    [activeTeam]
  );

  const myTurn = useMemo(() => activePlayer?.id === me?.id, [activePlayer, me]);
  const myTeamsTurn = useMemo(
    () => activeTeam?.id === me?.team,
    [activeTeam, me]
  );

  const currentMode = useMemo(() => session?.game.currentMode, [session]);
  const currentPhase = useMemo(() => session?.game.currentPhase, [session]);

  return {
    isAdmin,
    me,
    activePlayer,
    activeTeam,
    myTurn,
    myTeamsTurn,
    currentMode,
    currentPhase,
  };
}
