import { create } from "zustand";

export interface OptionsState {
  musicOn: boolean;
  musicVolume: number;

  effectsOn: boolean;
  effectsVolume: number;

  setMusicOn: (isOn: boolean) => void;
  setMusicVolume: (volume: number) => void;
  setEffectsOn: (isOn: boolean) => void;
  setEffectsVolume: (volume: number) => void;
}

export const useOptionsStore = create<OptionsState>((set) => {
  const store: OptionsState = {
    musicOn: localStorage.getItem("soso_music") !== "off",
    musicVolume: +localStorage.getItem("soso_music_volume") || 0.01,
    effectsOn: localStorage.getItem("soso_effects") !== "off",
    effectsVolume: +localStorage.getItem("soso_effects_volume") || 0.5,
    setMusicOn(isOn) {
      set((state) => {
        localStorage.setItem("soso_music", isOn ? "on" : "off");
        return {
          ...state,
          musicOn: isOn,
        };
      });
    },
    setMusicVolume(volume) {
      set((state) => {
        localStorage.setItem("soso_music_volume", volume + "");
        return {
          ...state,
          musicOn: volume > 0,
          musicVolume: volume,
        };
      });
    },
    setEffectsOn(isOn) {
      set((state) => {
        localStorage.setItem("soso_effects", isOn ? "on" : "off");
        return {
          ...state,
          effectsOn: isOn,
        };
      });
    },
    setEffectsVolume(volume) {
      set((state) => {
        localStorage.setItem("soso_effects_volume", volume + "");
        return {
          ...state,
          effectsOn: volume > 0,
          effectsVolume: volume,
        };
      });
    },
  };

  return store;
});
