import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/base/card";
import PageFrame from "../../components/layout/page-frame/pageFrame";
import { useSocket } from "../../hooks/useSocket.hook";
import { LobbyState } from "../../interfaces/session";
import { useSessionStore } from "../../store/session.store";
import { router } from "./router";
import Game from "./states/game-state/game";
import Lobby from "./states/lobby-state/lobby";
import Pick from "./states/pick-state/pick";
import Results from "./states/results-state/results";

const LobbyPage = () => {
  const { reconnectToLobby } = useSocket();
  const { session } = useSessionStore();
  const navigate = useNavigate();
  const { lobbyId } = useParams();

  const StateComp = useMemo(() => {
    switch (session?.state) {
      case LobbyState.LOBBY:
        return Lobby;
      case LobbyState.PICK:
        return Pick;
      case LobbyState.GAME:
        return Game;
      case LobbyState.RESULTS:
        return Results;
      default:
        return () => <div>Unexpected error</div>;
    }
  }, [session?.state]);

  useEffect(() => {
    if (!session) {
      const playerId = localStorage.getItem("soso_playerId");
      if (playerId) {
        reconnectToLobby(lobbyId, playerId);
      } else {
        router.navigate("/");
      }
    }
  }, [session, navigate, reconnectToLobby, lobbyId]);

  return (
    <PageFrame>
      <Card className="md:max-w-[90%] h-full flex flex-col">
        <StateComp />
      </Card>
    </PageFrame>
  );
};

export default LobbyPage;
