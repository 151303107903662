import { CSSProperties } from "react";

export enum TextVariant {
  H1 = "H1",
  STANDARD = "STANDARD",
  SMALL = "SMALL",
}

export interface TextProps {
  children: React.ReactNode;
  variant: TextVariant;
  className?: string;
  style?: CSSProperties;
}

const Text = ({ variant, className = "", style, children }: TextProps) => {
  switch (variant) {
    case TextVariant.H1:
      return (
        <h1
          style={style}
          className={`text-md md:text-2xl font-semibold ${className}`}
        >
          {children}
        </h1>
      );
    case TextVariant.SMALL:
      return (
        <small style={style} className={`text-sm ${className}`}>
          {children}
        </small>
      );
    default:
      return (
        <span style={style} className={className}>
          {children}
        </span>
      );
  }
};

export default Text;
