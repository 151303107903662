import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RiChatQuoteFill, RiFeedbackFill, RiPaletteFill } from "react-icons/ri";
import { GameMode } from "../../../../interfaces/game";

export interface GameConfigModeProps {
  mode: GameMode;
  className?: string;
  onClick?: () => void;
  hideText?: boolean;
}

const GameConfigMode = ({
  mode,
  onClick,
  className = "",
  hideText,
}: GameConfigModeProps) => {
  const { t } = useTranslation("lobby");
  const Icon = useMemo(() => {
    switch (mode) {
      case GameMode.EXPLAIN:
        return RiChatQuoteFill;
      case GameMode.WORD:
        return RiFeedbackFill;
      case GameMode.DRAW:
        return RiPaletteFill;
    }
  }, [mode]);

  const bgClass = useMemo(() => {
    switch (mode) {
      case GameMode.EXPLAIN:
        return "text-cyan-500";
      case GameMode.WORD:
        return "text-green-600";
      case GameMode.DRAW:
        return "text-yellow-600";
    }
  }, [mode]);

  return (
    <div
      onClick={onClick}
      className={`flex flex-col items-center ${bgClass} ${className} relative soso-bg hover:bg-white/10 p-1 md:p-4 aspect-square`}
    >
      <Icon className="flex-1 min-h-0 px-5 rounded-md w-24 md:w-36" />

      {!hideText && (
        <div className="text-xs md:text-xl font-medium whitespace-nowrap">
          {t(`game.mode.${mode}`)}
        </div>
      )}
    </div>
  );
};

export default GameConfigMode;
