import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiCheckFill,
  RiCloseFill,
  RiLinksFill,
  RiStarFill,
  RiUser5Fill,
} from "react-icons/ri";
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
} from "react-icons/tb";
import { useParams } from "react-router-dom";
import { useSessionProps } from "../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../hooks/useSocket.hook";
import { NotificationType } from "../../../interfaces/notification";
import { Player } from "../../../interfaces/player";
import { useNotificationStore } from "../../../store/notification.store";
import { useSessionStore } from "../../../store/session.store";

export interface PlayerListProps {
  className?: string;
}

interface PlayerListEntryProps {
  player: Player;
  isAdmin: boolean;
  isOwner: boolean;
  isMe: boolean;
}

const PlayerListEntry = ({
  player,
  isOwner,
  isAdmin,
  isMe,
}: PlayerListEntryProps) => {
  const { t } = useTranslation("lobby");
  const { kickPlayer, joinTeam } = useSocket();
  const kick = useCallback(
    () => kickPlayer(player.id),
    [kickPlayer, player.id]
  );

  return (
    <li
      className={`px-2 py-4 md:p-4 last:mb-0 rounded-md font-bold mb-1 md:mb-2 ${
        isMe ? "bg-white/10" : "soso-bg"
      } text-sm md:text-md flex items-center gap-2`}
      style={{ color: player.color }}
    >
      <div className="relative">
        <RiUser5Fill className="w-5 h-5 md:w-9 md:h-9" />
        {isOwner && (
          <RiStarFill
            size={8}
            className="absolute -top-1 -left-1 md:top-0 md:right-0 "
          />
        )}
      </div>

      <span
        className="flex-1 text-ellipsis min-w-0 overflow-hidden"
        title={player.name}
      >
        {player.name}
      </span>

      {isAdmin && !isMe && (
        <span className="flex-initial">
          <button onClick={kick} title={t("players.kick")}>
            <RiCloseFill className="w-5 h-5" />
          </button>
        </span>
      )}
      <span className="flex-initial hidden md:block">
        {
          <button
            className="text-xs text-center flex flex-col items-center"
            disabled={!isMe}
            onClick={() => joinTeam(player.team === 1 ? 2 : 1)}
          >
            {player.team === 1 ? (
              <TbSquareRoundedNumber1Filled className="w-6 h-6" />
            ) : (
              <TbSquareRoundedNumber2Filled className="w-6 h-6" />
            )}
            Team
          </button>
        }
      </span>
    </li>
  );
};

const InvitationLinkButton = () => {
  const { t } = useTranslation("lobby");
  const { sendNotification } = useNotificationStore();
  const { lobbyId } = useParams();

  const [copied, setCopied] = useState<boolean>(false);
  const timeoutRef = useRef<number>(null);

  const copyInvitationLink = () => {
    try {
      navigator.clipboard.writeText(
        `${window.location.origin}/?lobbyId=${lobbyId}`
      );

      setCopied(true);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => setCopied(false), 2000);
    } catch {
      sendNotification({
        message: t("link.notCopied"),
        type: NotificationType.ERROR,
      });
    }
  };

  return (
    <button
      className={`py-4 px-4 ${
        copied ? "bg-green-800 hover:bg-green-800" : "soso-bg hover:bg-white/10"
      } rounded-md w-full font-bold flex items-center justify-center gap-2`}
      onClick={copyInvitationLink}
    >
      {copied ? <RiCheckFill size={20} /> : <RiLinksFill size={20} />}
      <span className="flex-1 text-left text-sm md:text-md">
        {copied ? t("link.copiedButton") : t("link.copy")}
      </span>
    </button>
  );
};

const PlayerList = ({ className = "" }: PlayerListProps) => {
  const { joinTeam } = useSocket();
  const { session, ownId } = useSessionStore();
  const { isAdmin } = useSessionProps();

  const playersByTeam = useMemo(() => {
    const teams: Array<Player[]> = [[], []];
    session.players.forEach((p) => {
      teams[p.team - 1].push(p);
    });
    return teams;
  }, [session]);

  return (
    session && (
      <div className={`flex flex-col gap-2 overflow-hidden ${className}`}>
        <div className={`flex flex-1 gap-1 md:gap-2 overflow-y-auto`}>
          {playersByTeam.map((team, i) => {
            return (
              <ul key={`team_${i}`} className="flex-1 overflow-auto min-h-0">
                <button
                  onClick={() => joinTeam(i + 1)}
                  className="text-center text-md md:text-xl mb-2 md:mb-2 font-bold w-full soso-bg hover:bg-white/10 py-4"
                >
                  Team {i + 1}
                </button>
                {team.map((p) => (
                  <PlayerListEntry
                    key={p.id}
                    player={p}
                    isOwner={p.id === session.owner.id}
                    isAdmin={isAdmin}
                    isMe={p.id === ownId}
                  />
                ))}
              </ul>
            );
          })}
        </div>
        <InvitationLinkButton />
      </div>
    )
  );
};

export default PlayerList;
