import { useCallback } from "react";
import { LobbyEvents } from "../enums/lobby.enum";
import { useSessionStore } from "../store/session.store";

export const useSocket = (): {
  joinLobby: (lobbyId: string, playerName: string) => void;
  reconnectToLobby: (lobbyId: string, playerId: string) => void;
  createLobby: (playerName: string) => void;
  sendMessage: (message: string) => void;
  kickPlayer: (playerId: string) => void;
  joinTeam: (teamNumber: number) => void;
  nextLobbyState: () => void;
  submitPicks: (pickIndexes: number[]) => void;

  startRound: () => void;
  solveCard: () => void;
  skipCard: () => void;

  drawImage: (dataUrl: string) => void;
} => {
  const { socket } = useSessionStore();

  const createLobby = useCallback(
    (playerName: string) => {
      socket.emit(LobbyEvents.ACTION_CREATE, { playerName });
    },
    [socket]
  );

  const reconnectToLobby = useCallback(
    (lobbyId: string, playerId: string) => {
      socket.emit(LobbyEvents.ACTION_RECONNECT, { lobbyId, playerId });
    },
    [socket]
  );

  const joinLobby = useCallback(
    (lobbyId: string, playerName: string) => {
      socket.emit(LobbyEvents.ACTION_JOIN, { lobbyId, playerName });
    },
    [socket]
  );

  const sendMessage = useCallback(
    (message: string) => {
      socket.emit(LobbyEvents.ACTION_MESSAGE, { message });
    },
    [socket]
  );

  const kickPlayer = useCallback(
    (playerId: string) => {
      socket.emit(LobbyEvents.ACTION_KICK, { playerId });
    },
    [socket]
  );

  const joinTeam = useCallback(
    (teamNumber: number) => {
      socket.emit(LobbyEvents.ACTION_JOIN_TEAM, { teamNumber });
    },
    [socket]
  );

  const nextLobbyState = useCallback(() => {
    socket.emit(LobbyEvents.ACTION_NEXT_LOBBY_STATE);
  }, [socket]);

  const submitPicks = useCallback(
    (pickIndexes: number[]) => {
      socket.emit(LobbyEvents.ACTION_SUBMIT_PICKS, { pickIndexes });
    },
    [socket]
  );

  const startRound = useCallback(() => {
    socket.emit(LobbyEvents.ACTION_START_ROUND);
  }, [socket]);

  const solveCard = useCallback(() => {
    socket.emit(LobbyEvents.ACTION_SOLVE_CARD);
  }, [socket]);

  const skipCard = useCallback(() => {
    socket.emit(LobbyEvents.ACTION_SKIP_CARD);
  }, [socket]);

  const drawImage = useCallback(
    (dataUrl: string) => {
      socket.emit(LobbyEvents.ACTION_DRAW_IMAGE, { dataUrl });
    },
    [socket]
  );

  return {
    createLobby,
    joinLobby,
    reconnectToLobby,
    sendMessage,
    kickPlayer,
    joinTeam,
    nextLobbyState,
    submitPicks,
    startRound,
    solveCard,
    skipCard,
    drawImage,
  };
};
