import ChatForm from "./chatForm/chatForm";
import ChatList from "./chatList/chatList";

export interface ChatProps {
  className?: string;
}

const Chat = ({ className = "" }: ChatProps) => {
  return (
    <div className={`flex flex-col ${className} gap-2 h-full`}>
      <ChatList className="flex-1 min-h-0 h-full" />
      <ChatForm className="flex-initial" />
    </div>
  );
};

export default Chat;
