import { useTranslation } from "react-i18next";
import Button, { ButtonLevel } from "../../../../../components/base/button";
import PlayingCard from "../../../../../components/context/playing-card/playingCard";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../../hooks/useSocket.hook";
import { GameMode } from "../../../../../interfaces/game";
import { useSessionStore } from "../../../../../store/session.store";
import GameCanvas from "../game-canvas/GameCanvas";
import GuessingNotifications from "../guessing-notifications/guessingNotifications";
import { useGuessingSounds } from "../use-guessing-sounds/useGuessingSounds";

const GameStageRunning = () => {
  const { t } = useTranslation("game");
  const { myTurn, myTeamsTurn, activePlayer, currentMode } = useSessionProps();
  const { currentCard, imageDataUrl } = useSessionStore();
  const { solveCard, skipCard } = useSocket();

  useGuessingSounds();

  if (myTurn) {
    return currentCard ? (
      <div className="w-full h-full flex flex-col justify-end md:justify-center items-center py-1 gap-2 md:p-4 md:gap-4 min-h-0">
        <div className="flex flex-col-reverse md:flex-row gap-2 md:gap-4 min-h-0 w-full my-auto">
          {currentMode === GameMode.DRAW && (
            <div className="flex-1 w-full h-full min-h-0 min-w-0 max-w-[500px] ml-auto">
              <GameCanvas cardId={currentCard.id} />
            </div>
          )}

          <div className="md:hidden w-full h-full flex items-center justify-center flex-shrink flex-grow-0 flex-1 soso-bg p-2 rounded-md font-bold">
            {currentCard.title}
          </div>

          <PlayingCard
            className="flex-initial w-full h-fit my-auto mr-auto max-w-[240px] max-h-full hidden md:block"
            card={currentCard}
          />
        </div>
        <div className="flex gap-4 w-96 max-w-full">
          <Button
            className="flex-1"
            level={ButtonLevel.NEGATIVE}
            onClick={skipCard}
          >
            {t("action.skip")}
          </Button>
          <Button
            className="flex-1"
            level={ButtonLevel.POSITIVE}
            onClick={solveCard}
          >
            {t("action.solved")}
          </Button>
        </div>
      </div>
    ) : (
      <div>{t("hints.cardBeingDealt")}</div>
    );
  }

  const text = myTeamsTurn
    ? t("hints.teamPlayerIsActive", { player: activePlayer.name })
    : t("hints.opposingPlayerIsActive", { player: activePlayer.name });

  return (
    <div className="flex flex-col w-full h-full overflow-hidden min-h-0 min-w-0">
      <div className="text-center p-4 text-2xl">{text}</div>
      <div className="flex justify-center items-center gap-8">
        {currentMode === GameMode.DRAW && (
          <div className="flex items-center h-full overflow-hidden min-h-0 min-w-0 py-4">
            <img
              alt="drawnImage"
              className="w-auto h-auto object-contain bg-transparent rounded-md"
              src={imageDataUrl}
            />
          </div>
        )}
        <div className="w-60 my-4 relative">
          <PlayingCard className="w-60" card={null} showBack />
          <GuessingNotifications />
        </div>
      </div>
    </div>
  );
};

export default GameStageRunning;
