import { useTranslation } from "react-i18next";
import Input, { InputProps } from "../../base/input";
type UsernameInputProps = {} & Omit<
  InputProps,
  "label" | "name" | "placeholder"
>;

const UsernameInput = (props: UsernameInputProps) => {
  const { t } = useTranslation("fields");

  return (
    <Input
      placeholder={t("username.placeholder")}
      name="username"
      className="mt-8"
      classNameInput="text-center"
      maxLength={12}
      {...props}
    />
  );
};

export default UsernameInput;
