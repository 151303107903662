import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import { useSessionStore } from "../../../../../store/session.store";

export interface RemainingDeckProps {
  className?: string;
}

const RemainingDeck: FC<RemainingDeckProps> = ({ className }) => {
  const { t } = useTranslation("game");
  const { session } = useSessionStore();

  return (
    <div
      className={`min-h-0 w-8 h-8 md:w-16 md:h-16 flex items-center justify-center relative text-white ${className}`}
    >
      {session.game.remainingDeckCount === 0 && (
        <>
          <div className="hidden md:flex absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full rounded-md soso-bg items-center justify-center z-10">
            {t("hints.lastCard")}
          </div>
          <RiCloseFill className="md:hidden" size={16} />
        </>
      )}
      {new Array(session.game.remainingDeckCount).fill({}).map((_, i) => {
        return (
          <div
            key={"remainingDeck" + i}
            className="absolute text-sm md:text-lg left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 aspect-4/6 w-5 md:w-12 md:rounded-md border md:border-2 border-white bg-cyan-700 flex items-center justify-center z-50"
          >
            <img
              src="/logo.png"
              className="absolute left-2 right-2 opacity-10 w-2 md:w-8"
              alt=""
            />
            {i + 1}
          </div>
        );
      })}
    </div>
  );
};

export default RemainingDeck;
