import { KeyboardEventHandler, useCallback, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiMailSendFill } from "react-icons/ri";
import { useSocket } from "../../../../hooks/useSocket.hook";
import IconButton from "../../../base/iconButton";
import Input from "../../../base/input";

export interface ChatFormProps {
  className?: string;
  classNameInput?: string;
}

export interface ChatFormInputs extends FieldValues {
  message: string;
}

const ChatForm = ({ className = "", classNameInput = "" }: ChatFormProps) => {
  const { t } = useTranslation("lobby");
  const { register, handleSubmit, setValue } = useForm<ChatFormInputs>();
  const { sendMessage } = useSocket();
  const [lastMessage, setLastMessage] = useState(null);

  const onSubmit: SubmitHandler<ChatFormInputs> = useCallback(
    ({ message }) => {
      setLastMessage(message);
      sendMessage(message);
      setValue("message", "");
    },
    [sendMessage, setValue]
  );

  const onKeydown: KeyboardEventHandler = useCallback(
    (evt) => {
      if (evt.key === "ArrowUp" && lastMessage) {
        setValue("message", lastMessage);
        setTimeout(() => {
          (evt.target as HTMLInputElement).setSelectionRange(
            lastMessage.length,
            lastMessage.length
          );
        });
      }
    },
    [lastMessage, setValue]
  );

  return (
    <form className={`${className}`} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-2">
        <Input
          name="message"
          className="flex-1"
          classNameInput={classNameInput}
          register={register}
          placeholder={t("chat.placeholder")}
          autoComplete="off"
          onKeyDown={onKeydown}
          required
        />
        <IconButton className="flex-initial" type="submit">
          <RiMailSendFill size={16} className="w-5 h5" />
        </IconButton>
      </div>
    </form>
  );
};

export default ChatForm;
