import React, { useMemo } from "react";

export enum ButtonLevel {
  PRIMARY,
  POSITIVE,
  NEGATIVE,
  NEUTRAL,
  WHITE,
}

export type ButtonProps = {
  level?: ButtonLevel;
  outline?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = "",
      level = ButtonLevel.PRIMARY,
      outline,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const colors = useMemo(() => {
      switch (level) {
        case ButtonLevel.PRIMARY:
          return outline
            ? "border-2 border-cyan-600 hover:text-cyan-700 hover:border-cyan-700 action:border-cyan-600/90 text-cyan-600"
            : "bg-cyan-600 hover:bg-cyan-700 text-white active:bg-cyan-600/90";
        case ButtonLevel.POSITIVE:
          return outline
            ? "border-2 border-green-700 hover:text-green-800 hover:border-green-800 action:border-green-700/90 text-green-700"
            : "bg-green-700 hover:bg-green-800 text-white active:bg-green-700/90";
        case ButtonLevel.NEGATIVE:
          return outline
            ? "border-2 border-red-700 hover:text-red-800 hover:border-red-800 action:border-red-700/90 text-red-700"
            : "bg-red-700 hover:bg-red-800 text-white active:bg-red-700/90";
        case ButtonLevel.NEUTRAL:
          return outline
            ? "border-2 border-coal-800 hover:text-coal-700 hover:border-coal-700 action:border-coal-800/90 text-coal-800"
            : "bg-coal-800 hover:bg-coal-800 text-white active:bg-coal-800/90";
        case ButtonLevel.WHITE:
          return outline
            ? "border-2 border-white hover:text-gray-200 hover:border-gray-200 action:border-white/90 text-white"
            : "bg-white hover:bg-gray-100 text-black active:bg-white/90";
      }
    }, [level, outline]);

    return (
      <button
        ref={ref}
        className={`text-center p-3 text-lg font-bold focus:outline-2 focus:outline-white ${colors} ${
          props.disabled ? "opacity-75" : ""
        } font-semibold ${
          className?.includes("rounded-") ? "" : "rounded-md"
        } ${className}`}
        {...props}
      />
    );
  }
);

export default Button;
