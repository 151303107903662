import { Player } from "./player";

export enum GameMode {
  EXPLAIN = "EXPLAIN",
  WORD = "WORD",
  DRAW = "DRAW",
}

export enum GamePhase {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  RESULT = "RESULT",
}

export interface Team {
  id: number;
  players: Player[];
  currentPlayerIndex: number;
  currentPlayerId: string;
}

export interface Score {
  byMode: {
    [mode in GameMode]: { [team: number]: number };
  };
  total: { [team: number]: number };
}

export interface Game {
  modes: GameMode[];
  phases: GamePhase[];
  currentMode: GameMode;
  currentPhase: GamePhase;
  done: boolean;
  timerEnd: string;
  deckConfig: {
    cardsPerPlayer: number;
    picksPerPlayer: number;
  };
  teams: {
    [teamNumber: number]: Team;
  };
  currentTeamId: number;
  remainingDeckCount: number;
  score: Score;
}
