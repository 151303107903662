const LobbyLocalesEN = {
  foo: "barEN",
  link: {
    copy: "Copy invitation link",
    copied: "Link copied to clipboard!",
    notCopied: "Couldn't copy invitation link.",
  },
  chat: {
    placeholder: "Your message goes here...",
  },
  players: {
    kick: "Kick",
  },
  game: {
    mode: {
      EXPLAIN: "Explain",
      WORD: "One Word",
      DRAW: "Draw",
    },
    start: "Start game",
  },
};

export default LobbyLocalesEN;
