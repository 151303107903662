import moment from "moment";
import { useMemo } from "react";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";
import { useSessionStore } from "../../../../../store/session.store";
import GameConfigMode from "../../../game-config/game-config-mode/gameConfigMode";
import CountdownTimer from "../countdown/CountdownTimer";
import RemainingDeck from "../remaining-deck/RemainingDeck";
export interface GameStateProps {
  className?: string;
}
const GameState = ({ className }: GameStateProps) => {
  const { currentMode } = useSessionProps();

  const { session } = useSessionStore();
  const timerEnd = session.game.timerEnd;
  const timerEndDate = useMemo(() => {
    if (!timerEnd) {
      return null;
    }

    return moment(timerEnd).toDate();
  }, [timerEnd]);

  return (
    <div
      className={`w-full h-full items-center grid grid-cols-3 gap-4 p-4 ${className}`}
    >
      <GameConfigMode className="w-full mx-auto" mode={currentMode} />
      <div className="soso-bg flex items-center justify-center aspect-square">
        <RemainingDeck className="flex-1" />
      </div>
      <CountdownTimer className="text-right" targetDate={timerEndDate} />
    </div>
  );
};

export default GameState;
