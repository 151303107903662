import { useEffect } from "react";
import useSound from "use-sound";
import { useOptionsStore } from "../../../../../store/options.store";
import { useSessionStore } from "../../../../../store/session.store";

export function useGuessingSounds() {
  const { effectsVolume, effectsOn } = useOptionsStore();
  const { lastGuessedCardId, lastSkippedCardId } = useSessionStore();
  const [playGuessedSound] = useSound("/card-guessed.mp3", {
    volume: effectsOn ? effectsVolume : 0,
  });
  const [playSkippedSound] = useSound("/card-skip.wav", {
    volume: effectsOn ? effectsVolume : 0,
  });

  useEffect(() => {
    if (!lastGuessedCardId) {
      return;
    }

    playGuessedSound();
  }, [playGuessedSound, lastGuessedCardId]);

  useEffect(() => {
    if (!lastSkippedCardId) {
      return;
    }

    playSkippedSound();
  }, [playSkippedSound, lastSkippedCardId]);
}
