import { FieldError, UseFormRegister } from "react-hook-form";
import Text, { TextVariant } from "./text";

export type InputProps = {
  name: string;
  register: UseFormRegister<any>;

  classNameInput?: string;
  label?: string;
  error?: FieldError;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = ({
  className = "",
  classNameInput = "",
  register,
  required,
  error,
  label,
  ...props
}: InputProps) => {
  return (
    <label className={`block relative ${className}`} htmlFor={props.name}>
      {label && (
        <Text
          className="ml-1 text-coal-700 dark:text-coal-200"
          variant={TextVariant.SMALL}
        >
          {label}
        </Text>
      )}
      <input
        className={`
        w-full rounded-md p-2 focus:outline-none 
        bg-transparent focus:bg-white/10 placeholder:text-coal-200 border-white/50
        shadow-inner border-2 font-bold placeholder:font-thin ${classNameInput}`}
        {...register(props.name, { required })}
        {...props}
      />
      {error?.type === "required" && (
        <Text
          className="text-red-400 absolute top-full right-1"
          variant={TextVariant.SMALL}
        >
          This field is required.
        </Text>
      )}
    </label>
  );
};

export default Input;
