import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/base/button";
import { useSocket } from "../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../store/session.store";
import GameConfigModes from "./game-config-modes/gameConfigModes";
import GameConfigSettings from "./game-config-settings/gameConfigSettings";

export interface GameConfigProps {
  className?: string;
  isAdmin: boolean;
  hideStartButton?: boolean;
}

const GameConfig = ({
  className = "",
  isAdmin,
  hideStartButton,
}: GameConfigProps) => {
  const { t } = useTranslation("lobby");
  const { session } = useSessionStore();
  const game = useMemo(() => session?.game, [session]);
  const { nextLobbyState } = useSocket();

  return (
    game && (
      <div
        className={`grid grid-cols-1 grid-rows-3 lg:grid-cols-2 lg:grid-rows-2 gap-2 items-center justify-center px-2 md:px-8 py-6 soso-bg rounded-md ${className}`}
      >
        <GameConfigSettings className="row-start-1 row-end-3" />
        <GameConfigModes />
        <div className="w-full h-full soso-bg rounded-md flex items-center justify-center">
          {isAdmin && !hideStartButton && (
            <Button
              className="mx-auto px-10 py-3 w-full h-full"
              onClick={nextLobbyState}
            >
              {t("game.start")}
            </Button>
          )}
          {!isAdmin && (
            <span>{t("hint.prepares", { player: session.owner.name })}</span>
          )}
        </div>
      </div>
    )
  );
};

export default GameConfig;
