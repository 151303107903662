const PickLocalesDE = {
  headlines: {
    availableOptions: "Verfügbare Optionen",
    pickedOptions: "Deine Auswahl",
  },
  instructions: {
    pick: "Wähle aus diesen Karten deine {{toBePicked}} Favoriten aus!",
    submit:
      "Das sind genug Karten! Bist du zufrieden oder möchtest du noch etwas ändern?",
    done: "Perfekt!\nLass uns noch auf den Rest warten, dann geht's los!",
  },
  action: {
    pick: "Karte hinzufügen",
    remove: "Karte entfernen",
    done: "Auswahl bestätigen",
    random: "Zufällige Auswahl treffen",
  },
  hint: {
    skip: "Überspringen",
    pick: "Auswählen",
  },
};

export default PickLocalesDE;
