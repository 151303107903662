const LobbyLocalesDE = {
  heading: "Lobby von",
  link: {
    copy: "Einladungslink kopieren",
    copiedButton: "Link erfolgreich kopiert!",
    notCopied: "Link konnte nicht kopiert werden.",
  },
  chat: {
    placeholder: "Tipp deine Nachricht...",
  },
  players: {
    kick: "Rauswerfen",
  },
  game: {
    mode: {
      EXPLAIN: "Erklären",
      WORD: "Ein Wort",
      DRAW: "Malen",
    },
    start: "Spiel starten",
  },
  tabs: {
    players: "Spieler",
    settings: "Einstellungen",
    chat: "Chat",
  },
  hint: {
    prepares: "{{player}} bereitet das Spiel vor.",
  },
};

export default LobbyLocalesDE;
