import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiChat1Fill, RiSettings5Fill, RiUser5Fill } from "react-icons/ri";
import Button from "../../../../components/base/button";
import Text, { TextVariant } from "../../../../components/base/text";
import Chat from "../../../../components/context/chat/chat";
import { useSessionProps } from "../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../hooks/useSocket.hook";
import { useSessionStore } from "../../../../store/session.store";
import GameConfig from "../../game-config/gameConfig";
import PlayerList from "../../player-list/playerList";

enum Tabs {
  PLAYERS,
  SETTINGS,
  CHAT,
}

const LobbySm = () => {
  const { t } = useTranslation("lobby");
  const { isAdmin } = useSessionProps();
  const { nextLobbyState } = useSocket();

  const [tab, setTab] = useState<number>(Tabs.PLAYERS);

  return (
    <div className="flex flex-col lg:hidden h-full">
      <div className="flex flex-col flex-1">
        <div className="flex mb-4 gap-1">
          <button
            className={`flex justify-center items-center gap-1 font-bold py-3 flex-grow ${
              tab === Tabs.PLAYERS ? "text-cyan-500 bg-white/10" : "soso-bg"
            }`}
            onClick={() => setTab(Tabs.PLAYERS)}
          >
            <RiUser5Fill size={32} />
          </button>
          <button
            className={`flex justify-center items-center font-bold py-3 flex-grow ${
              tab === Tabs.SETTINGS ? "text-cyan-500 bg-white/10" : "soso-bg"
            }`}
            onClick={() => setTab(Tabs.SETTINGS)}
          >
            <RiSettings5Fill size={32} />
          </button>
          <button
            className={`flex justify-center items-center font-bold py-3 flex-grow ${
              tab === Tabs.CHAT ? "text-cyan-500 bg-white/10" : "soso-bg"
            }`}
            onClick={() => setTab(Tabs.CHAT)}
          >
            <RiChat1Fill size={32} />
          </button>
        </div>
        {tab === Tabs.PLAYERS && <PlayerList className="h-full" />}
        {tab === Tabs.SETTINGS && (
          <GameConfig isAdmin={isAdmin} hideStartButton />
        )}
        {tab === Tabs.CHAT && <Chat />}
      </div>
      {isAdmin && (
        <div className="py-2 mt-2 px-0">
          <Button className="w-full" onClick={nextLobbyState}>
            {t("game.start")}
          </Button>
        </div>
      )}
    </div>
  );
};

const LobbyLg = () => {
  const { t } = useTranslation("lobby");
  const { session } = useSessionStore();
  const { isAdmin } = useSessionProps();

  return (
    <div className="hidden lg:flex flex-col h-full">
      <Text
        className="mb-4 px-6 flex-initial flex soso-bg py-6"
        variant={TextVariant.H1}
      >
        <img src="/logo.png" alt="Name It! Logo" className="w-8 h-8 mr-4" />{" "}
        {t("heading")}&nbsp;
        <span className="text-cyan-400">{session?.owner.name}</span>
      </Text>
      <div className="flex flex-col md:flex-row gap-4 flex-1 min-h-0 h-full">
        <PlayerList className="flex-2" />
        <div className="flex-3 flex flex-col gap-4">
          <GameConfig
            isAdmin={isAdmin}
            className="flex-1 flex-shrink-0 min-h-0"
          />
          <Chat className="flex-1 flex-shrink-0 min-h-0" />
        </div>
      </div>
    </div>
  );
};

const Lobby = () => {
  return (
    <>
      <LobbySm />
      <LobbyLg />
    </>
  );
};

export default Lobby;
