import { FC, useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";

export interface DialogProps {
  children: React.ReactNode;
  onClose: () => void;
}

const Dialog: FC<DialogProps> = ({ children, onClose }) => {
  useEffect(() => {
    const closeOnEsc = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", closeOnEsc);

    return () => document.removeEventListener("keydown", closeOnEsc);
  });

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black/90 z-50">
      <div className="bg-black relative rounded-md overflow-hidden">
        <div className="w-full h-full soso-bg p-4">
          <button
            className="absolute top-4 right-4 rounded-full hover:soso-bg"
            onClick={onClose}
          >
            <RiCloseFill size={32} />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
