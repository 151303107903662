import { FC } from "react";
import ButtonBar from "../../../../components/base/buttonBar";

export enum GuessingModes {
  CHAT = "CHAT",
  MANUAL = "MANUAL",
  CHAT_AND_MANUAL = "CHAT_AND_MANUAL",
}

export interface GameConfigSettingsProps {
  className?: string;
}

const GameConfigSettings: FC<GameConfigSettingsProps> = ({ className }) => {
  return (
    <div
      className={`gap-1 grid grid-cols-[max-content_1fr] h-full w-full auto-rows-fr text-sm md:text-md ${className}`}
    >
      <div className="soso-bg p-2 flex items-center justify-center gap-1 relative rounded-tl-md font-bold">
        Ratemodus
      </div>
      <div className="text-center py-1 px-3 soso-bg rounded-tr-md">
        <ButtonBar
          value={GuessingModes.CHAT_AND_MANUAL}
          onClick={() => null}
          buttons={[
            { text: "Chat", buttonValue: GuessingModes.CHAT, disabled: true },
            {
              text: "Manuell",
              buttonValue: GuessingModes.MANUAL,
              disabled: true,
            },
            {
              text: "Beides",
              buttonValue: GuessingModes.CHAT_AND_MANUAL,
              disabled: true,
            },
          ]}
        />
      </div>
      <div className="soso-bg py-2 px-3 flex items-center justify-center gap-1 relative font-bold">
        Rundenzeit
      </div>
      <div className="flex items-center justify-center py-1 px-2 soso-bg">
        60 Sekunden
      </div>
      <div className="soso-bg py-2 px-3 flex items-center justify-center gap-1 relative font-bold">
        Deckgröße
      </div>
      <div className="flex items-center justify-center py-1 px-2 soso-bg rounded-br-md">
        40 Karten
      </div>
      <div className="soso-bg py-2 px-3 flex items-center justify-center gap-1 relative rounded-bl-md font-bold">
        Überspringen
      </div>
      <div className="flex items-center justify-center py-1 px-2 soso-bg rounded-br-md">
        -0 Sekunden
      </div>
      <div className="py-2 px-3 flex items-center justify-center gap-1 relative rounded-bl-md font-bold"></div>
      <div className="flex items-center justify-center py-1 px-2 rounded-br-md"></div>
      <div className="py-2 px-3 flex items-center justify-center gap-1 relative rounded-bl-md font-bold"></div>
      <div className="flex items-center justify-center py-1 px-2 rounded-br-md"></div>
    </div>
  );
};

export default GameConfigSettings;
