import React, { CSSProperties, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { TbQuestionMark } from "react-icons/tb";
import { Card } from "../../../interfaces/card";
import Button, { ButtonLevel } from "../../base/button";
import Text, { TextVariant } from "../../base/text";

export interface PlayingCardProps {
  className?: string;
  card?: Card;
  pickable?: boolean;
  picked?: boolean;
  disabled?: boolean;
  onPickToggle?: () => void;
  style?: CSSProperties;
  onClick?: (evt: MouseEvent, card: Card) => void;
  showBack?: boolean;
}

const PlayingCard = ({
  className = "",
  card,
  pickable,
  picked,
  onPickToggle,
  disabled,
  style,
  onClick,
  showBack,
}: PlayingCardProps) => {
  const { t } = useTranslation("pick");

  return (
    <div
      className={`${className.includes("absolute") ? "" : "relative"} ${
        onClick ? "cursor-pointer" : ""
      } text-white aspect-4/6 h-auto mx-auto p-1 bg-cyan-700 rounded-2xl border-8 ${className}`}
      style={style}
      onClick={(evt) => onClick && onClick(evt, card)}
    >
      {!showBack && (
        <div className={`relative h-full rounded-2xl p-1 flex flex-col gap-2`}>
          <Text
            className="flex-1 flex flex-col justify-center text-center relative z-10 overflow-hidden break-words"
            variant={TextVariant.H1}
          >
            {card && card.title}
          </Text>
          <div className="border-b-white border-b-2 relative z-10" />
          <Text
            className="flex-2 flex flex-col justify-center overflow-hidden text-ellipsis relative z-10"
            variant={TextVariant.SMALL}
          >
            {card && card.description}
          </Text>
          <img
            src="/logo.png"
            alt="logo"
            className="absolute inset-0 flex justify-center items-center p-8 z-0 opacity-10 object-contain m-auto "
          />
        </div>
      )}
      {showBack && (
        <div className="w-full h-full flex justify-center items-center flex-col">
          <TbQuestionMark size={"100%"} className={`font-bold text-white/25`} />
        </div>
      )}
      {pickable && (
        <Button
          type="button"
          className="absolute -bottom-14 right-0 left-0 w-full py-2 px-3 text-sm mx-auto"
          level={picked ? ButtonLevel.NEGATIVE : ButtonLevel.POSITIVE}
          onKeyDown={(evt) => evt.key === " " && evt.stopPropagation()}
          onClick={(evt) => {
            if (onPickToggle) {
              evt.stopPropagation();
              onPickToggle();
            }
          }}
          disabled={disabled}
        >
          {picked ? t("action.remove") : t("action.pick")}
        </Button>
      )}
    </div>
  );
};

export default React.forwardRef(PlayingCard);
