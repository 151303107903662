import { FC, TouchEvent, useCallback, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PlayingCard from "../../../../../components/context/playing-card/playingCard";
import { PickFormInputs, PickableCard } from "../pick";

export interface PickSwipeProps {
  cards: PickableCard[];
  className?: string;
  morePicksNeeded: boolean;
}

const SWIPE_TRHESHOLD = 60;

const PickSwipe: FC<PickSwipeProps> = ({
  cards,
  className = "",
  morePicksNeeded,
}) => {
  const { t } = useTranslation("pick");

  const unpickedCards = useMemo(
    () => cards.filter((c) => !c.selected),
    [cards]
  );

  const [moveX, setMoveX] = useState<number>(0);
  const [startX, setStartX] = useState<number>(0);
  const [dir, setDir] = useState<-1 | 0 | 1>(0);
  const { setValue } = useFormContext<PickFormInputs>();

  const selectLastCard = useCallback(() => {
    const newCardPicks = [...cards];
    const lastUnpickedCard = unpickedCards[unpickedCards.length - 1];
    const toBeSelected = newCardPicks.find((c) => c.id === lastUnpickedCard.id);
    toBeSelected.selected = true;
    setValue("cardPicks", newCardPicks);
  }, [cards, unpickedCards, setValue]);

  const moveLastCardDown = useCallback(() => {
    const newCardPicks = [...cards];
    const lastUnpickedCard = unpickedCards[unpickedCards.length - 1];
    const toBeMovedIndex = newCardPicks.findIndex(
      (c) => c.id === lastUnpickedCard.id
    );
    const toBeMovedCard = newCardPicks[toBeMovedIndex];
    newCardPicks.splice(toBeMovedIndex, 1);
    newCardPicks.unshift(toBeMovedCard);
    setValue("cardPicks", newCardPicks);
  }, [cards, setValue, unpickedCards]);

  const handleTouchStart = useCallback((evt: TouchEvent<HTMLDivElement>) => {
    setStartX(evt.touches[0].clientX);
  }, []);

  const handleTouchMove = useCallback(
    (evt: TouchEvent<HTMLDivElement>) => {
      let moveX = evt.touches[0].clientX - startX;
      if (!morePicksNeeded) {
        moveX = Math.min(0, moveX);
      }

      setMoveX(moveX);
    },
    [morePicksNeeded, startX]
  );

  const dirRef = useRef<number>();
  dirRef.current = dir;
  const handleTouchEnd = useCallback(() => {
    const reset = () => {
      if (dirRef.current > 0) {
        selectLastCard();
      } else if (dirRef.current < 0) {
        moveLastCardDown();
      }
      setDir(0);
    };

    if (Math.abs(moveX) > SWIPE_TRHESHOLD) {
      setDir(moveX > 0 ? 1 : -1);
    }
    setStartX(null);
    setMoveX(null);
    setTimeout(reset, 300);
  }, [moveX, selectLastCard, moveLastCardDown]);

  const transform = useMemo(() => {
    if (!dir && !moveX) {
      return null;
    }

    if (dir) {
      if (dir === 1) {
        return "translateX(200%) rotate(25deg)";
      } else {
        return "translateX(-200%) rotate(-25deg)";
      }
    }

    return `translateX(${moveX}px) rotate(${moveX / 30}deg)`;
  }, [dir, moveX]);

  const bg = useMemo(() => {
    if (Math.abs(moveX) <= SWIPE_TRHESHOLD) {
      return "";
    }

    if (moveX > 0) {
      return "bg-green-500/25";
    }

    return "bg-white/25";
  }, [moveX]);

  return (
    <div
      className={`w-full h-full relative ${bg} transition-colors ${className}`}
    >
      <div className="absolute left-4 top-1/2 -rotate-90 -translate-x-1/2 text-2xl text-white/50 z-50">
        {t("hint.skip")}
      </div>
      {unpickedCards.map((c, i) => (
        <div
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4"
          key={c.id}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchEnd}
        >
          <PlayingCard
            card={c}
            style={
              i === unpickedCards.length - 1
                ? {
                    transition: !moveX && "transform ease 300ms",
                    transform,
                    transformOrigin: "0 100%",
                  }
                : null
            }
          />
        </div>
      ))}
      <div className="absolute right-4 top-1/2 rotate-90 translate-x-1/2 text-2xl text-white/50 z-50">
        {t("hint.pick")}
      </div>
    </div>
  );
};

export default PickSwipe;
