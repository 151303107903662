import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import FieldsLocalesDE from "./fields/fields.de";
import FieldsLocalesEN from "./fields/fields.en";
import GameLocalesDE from "./game/game.de";
import GameLocalesEN from "./game/game.en";
import HomeLocalesDE from "./home/home.de";
import HomeLocalesEN from "./home/home.en";
import LobbyLocalesDE from "./lobby/lobby.de";
import LobbyLocalesEN from "./lobby/lobby.en";
import PickLocalesDE from "./pick/pick.de";
import PickLocalesEN from "./pick/pick.en";
import ResultsLocalesDE from "./results/results.de";
import ResultsLocalesEN from "./results/results.en";

const resources = {
  en: {
    lobby: LobbyLocalesEN,
    home: HomeLocalesEN,
    pick: PickLocalesEN,
    game: GameLocalesEN,
    results: ResultsLocalesEN,

    fields: FieldsLocalesEN,
  },
  de: {
    lobby: LobbyLocalesDE,
    home: HomeLocalesDE,
    pick: PickLocalesDE,
    game: GameLocalesDE,
    results: ResultsLocalesDE,

    fields: FieldsLocalesDE,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "en",
});
