import React from "react";

export interface ColorButtonProps {
  color: string;
  setColor: (color: string) => void;
}

const ColorButton: React.FC<ColorButtonProps> = ({ color, setColor }) => {
  return (
    <button
      className="block w-6 h-6 border border-gray-200"
      onClick={() => setColor(color)}
      style={{ background: color }}
    ></button>
  );
};

export default ColorButton;
