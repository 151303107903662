import { useEffect, useMemo, useState } from "react";

export enum RenderWidth {
  SM = "SM",
  MD = "MD",
}

function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): T {
  let timeout: ReturnType<typeof setTimeout>;
  // @ts-ignore
  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      timeout = null as any;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  } as T;
}

export function useRenderWidth(): RenderWidth {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 200);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderWidth = useMemo(() => {
    if (width >= 768) {
      return RenderWidth.MD;
    }

    return RenderWidth.SM;
  }, [width]);

  return renderWidth;
}
