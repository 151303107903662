import { FC } from "react";

export interface ButtonBarProps {
  onClick: (value: any) => void;
  value: any;
  buttons: { text: string; buttonValue: any; disabled?: boolean }[];
}

const ButtonBar: FC<ButtonBarProps> = ({ buttons, onClick, value }) => {
  return (
    <div className="rounded-2xl overflow-hidden flex gap-1 h-full w-full">
      {buttons.map(({ text, buttonValue, disabled }, i) => {
        return (
          <button
            className={`flex-grow flex-shrink ${
              value === buttonValue
                ? "bg-cyan-700 font-bold"
                : disabled
                ? "soso-bg"
                : "bg-black/10 dark:bg-white/10"
            }`}
            key={i}
            onClick={() => onClick(buttonValue)}
            disabled={disabled}
          >
            {text}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonBar;
