import { io, Socket } from "socket.io-client";
import { create } from "zustand";
import { LobbyEvents } from "../enums/lobby.enum";
import { Card } from "../interfaces/card";
import { Session } from "../interfaces/session";
import { router } from "../pages/lobby/router";

export interface SessionState {
  session: Session;
  socket: Socket;
  ownId: string;
  imageDataUrl: string;

  cardChoices: Card[];
  currentCard: Card;
  lastSkippedCardId: number;
  lastGuessedCardId: number;

  updateSession: (session: Session) => void;
  setOwnId: (update: { playerId: string }) => void;
  setCardChoices: (payload: { cardChoices: Card[] }) => void;
  setCurrentCard: (payload: { card: Card }) => void;
  setImageDataUrl: (payload: { dataUrl: string }) => void;
  setLastGuessedCard: (payload: { cardId: number }) => void;
  setLastSkippedCard: (payload: { cardId: number }) => void;
  handleReconnectionFailure: () => void;
  handleKicked: () => void;
}

export const useSessionStore = create<SessionState>((set) => {
  const socket = io(
    process.env.REACT_APP_BACKEND_URL || "http://localhost:5555"
  );

  const store: SessionState = {
    session: null,
    ownId: null,
    cardChoices: null,
    currentCard: null,
    imageDataUrl: null,
    lastSkippedCardId: null,
    lastGuessedCardId: null,
    socket,
    updateSession: (session: Session) =>
      set((state) => ({ ...state, session })),
    setOwnId: (update: { playerId: string }) => {
      localStorage.setItem("soso_playerId", update.playerId);
      return set((state) => ({ ...state, ownId: update.playerId }));
    },
    setCardChoices: (payload: { cardChoices: Card[] }) =>
      set((state) => ({ ...state, cardChoices: payload.cardChoices })),
    setCurrentCard: (payload: { card: Card }) =>
      set((state) => ({ ...state, currentCard: payload.card })),
    setImageDataUrl: (payload: { dataUrl: string }) =>
      set((state) => ({ ...state, imageDataUrl: payload.dataUrl })),
    setLastGuessedCard: (payload: { cardId: number }) =>
      set((state) => ({ ...state, lastGuessedCardId: payload.cardId })),
    setLastSkippedCard: (payload: { cardId: number }) =>
      set((state) => ({ ...state, lastSkippedCardId: payload.cardId })),
    handleReconnectionFailure: () => router.navigate("/"),
    handleKicked: () => {
      router.navigate("/");
      set((state) => ({ ...state, session: null }));
    },
  };

  socket.on(LobbyEvents.UPDATE, store.updateSession);
  socket.on(LobbyEvents.UPDATE_IMAGE, store.setImageDataUrl);
  socket.on(LobbyEvents.UPDATE_CARD_GUESSED, store.setLastGuessedCard);
  socket.on(LobbyEvents.UPDATE_CARD_SKIPPED, store.setLastSkippedCard);

  socket.on(LobbyEvents.YOU_JOINED, store.setOwnId);
  socket.on(LobbyEvents.YOU_CARD_PICKS, store.setCardChoices);
  socket.on(LobbyEvents.YOU_NEW_CARD, store.setCurrentCard);
  socket.on(LobbyEvents.YOU_KICKED, store.handleKicked);

  socket.on(LobbyEvents.MISSING, () => {
    router.navigate("/");
  });

  socket.on("disconnect", (reason: string) => {
    console.debug("Disconnected: %s", reason);
    store.updateSession(null);

    const pathParts = window.location.pathname.split("/");
    const lobbyIndex = pathParts.indexOf("lobby");

    if (lobbyIndex >= 0 && lobbyIndex + 1 < pathParts.length) {
      const lobbyId = pathParts[lobbyIndex + 1];
      const url = new URL(window.location.origin + "/");
      url.searchParams.set("lobbyId", lobbyId);
      router.navigate(`/reconnect/${lobbyId}`);
    } else {
      router.navigate("/");
    }
  });

  return store;
});
