import { createBrowserRouter } from "react-router-dom";
import HomePage from "../home/home.page";
import ReconnectPage from "../reconnect/reconnect.page";
import LobbyPage from "./lobby.page";

export const router = createBrowserRouter([
  {
    path: "/lobby/:lobbyId",
    element: <LobbyPage />,
  },
  {
    path: "/reconnect/:lobbyId",
    element: <ReconnectPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
]);
