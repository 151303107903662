import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStore } from "../../../../../store/session.store";
import "./guessingNotifications.css";

export interface GuessingNotificationsProps {
  className?: string;
}

const GuessingNotifications = ({ className }: GuessingNotificationsProps) => {
  const { t } = useTranslation("game");
  const { lastGuessedCardId, lastSkippedCardId } = useSessionStore();
  const [notifications, setNotifications] = useState<
    { type: "success" | "skip"; id: number }[]
  >([]);
  const timeoutRef = useRef<number>();
  const [state, setState] = useState<"success" | "skip" | null>(null);

  const resetState = useCallback(() => {
    setState(null);
  }, []);

  const onAnimationEnd = useCallback((id: number) => {
    setNotifications((curr) => {
      const nts = [...curr];
      nts.pop();
      return nts;
    });
  }, []);

  const updateState = useCallback(
    (state: "success" | "skip") => {
      setState(state);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(resetState, 1000);
    },
    [resetState]
  );

  useEffect(() => {
    if (!lastGuessedCardId) {
      return;
    }

    updateState("success");
    const id = Math.floor(Math.random() * 100000000);
    setNotifications((curr) => [{ id, type: "success" }, ...curr]);
    window.setTimeout(() => onAnimationEnd(id), 1000);
  }, [lastGuessedCardId, onAnimationEnd, updateState]);

  useEffect(() => {
    if (!lastSkippedCardId) {
      return;
    }

    updateState("skip");
    const id = Math.floor(Math.random() * 100000000);
    setNotifications((curr) => [{ id, type: "skip" }, ...curr]);
    setTimeout(() => onAnimationEnd(id), 1000);
  }, [lastSkippedCardId, onAnimationEnd, updateState]);

  const stateClasses = useMemo(() => {
    switch (state) {
      case "success":
        return "bg-green-500/20";
      case "skip":
        return "bg-white/20";
      default:
        return "bg-transparent";
    }
  }, [state]);

  return (
    <div
      className={`absolute inset-0 transition-colors ${className} ${stateClasses}`}
    >
      {notifications.map((n) => (
        <div
          key={n.id}
          className={`guessing-notification text-lg ${
            n.type === "success" ? "text-green-500" : "text-gray-500"
          }`}
        >
          {n.type === "success" ? t("hints.guessed") : t("hints.skipped")}
        </div>
      ))}
    </div>
  );
};

export default GuessingNotifications;
