import { useMemo } from "react";
import { GamePhase } from "../../../../../interfaces/game";
import { useSessionStore } from "../../../../../store/session.store";
import GameStagePending from "./gameStagePending";
import GameStageResults from "./gameStageResults";
import GameStageRunning from "./gameStageRunning";

const GameStage = () => {
  const { session } = useSessionStore();

  const StagePhase = useMemo(() => {
    switch (session.game.currentPhase) {
      case GamePhase.PENDING:
        return GameStagePending;
      case GamePhase.RUNNING:
        return GameStageRunning;
      case GamePhase.RESULT:
        return GameStageResults;
      default:
        return () => <div>{session.game.currentPhase}</div>;
    }
  }, [session.game.currentPhase]);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <StagePhase />
    </div>
  );
};

export default GameStage;
