import { FC } from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonLevel } from "../../../../../components/base/button";
import { useSessionProps } from "../../../../../hooks/useSessionProps.hook";

export interface PickSubmitButtonProps {
  morePicksNeeded: boolean;
  pickedCardCount: number;
  neededPickCount: number;
  className?: string;
}

const PickSubmitButton: FC<PickSubmitButtonProps> = ({
  morePicksNeeded,
  pickedCardCount,
  neededPickCount,
  className,
}) => {
  const { me } = useSessionProps();
  const { t } = useTranslation("pick");

  return (
    <Button
      level={ButtonLevel.NEUTRAL}
      type="submit"
      className={`relative overflow-hidden min-w-max mx-auto ${className}`}
      disabled={morePicksNeeded || me.hasPicked}
    >
      &nbsp;
      <div
        className={`absolute bg-gradient-to-r from-cyan-500 to-cyan-700 inset-0 pointer-events-none z-10 transition-transform`}
        style={{
          transformOrigin: "left",
          transform: `scaleX(${(pickedCardCount / neededPickCount).toFixed(
            2
          )})`,
        }}
      ></div>
      <div className="absolute inset-0 flex items-center justify-center z-20">
        {morePicksNeeded
          ? `${pickedCardCount}/${neededPickCount}`
          : t("action.done")}
      </div>
    </Button>
  );
};

export default PickSubmitButton;
