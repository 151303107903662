export enum LobbyEvents {
  ACTION_CREATE = "LOBBY_ACTION_CREATE",
  ACTION_JOIN = "LOBBY_ACTION_JOIN",
  ACTION_RECONNECT = "LOBBY_ACTION_RECONNECT",
  ACTION_MESSAGE = "LOBBY_ACTION_MESSAGE",
  ACTION_KICK = "LOBBY_ACTION_KICK",
  ACTION_JOIN_TEAM = "LOBBY_ACTION_JOIN_TEAM",
  ACTION_NEXT_LOBBY_STATE = "LOBBY_ACTION_NEXT_LOBBY_STATE",
  ACTION_SUBMIT_PICKS = "LOBBY_ACTION_SUBMIT_PICKS",
  ACTION_START_ROUND = "LOBBY_ACTION_START_ROUND",
  ACTION_SOLVE_CARD = "LOBBY_ACTION_SOLVE_CARD",
  ACTION_SKIP_CARD = "LOBBY_ACTION_SKIP_CARD",
  ACTION_DRAW_IMAGE = "LOBBY_ACTION_DRAW_IMAGE",

  ERROR_LOBBY_NOT_READY = "LOBBY_ERROR_NOT_READY",
  ERROR_RECONNECT_FAILED = "LOBBY_ERROR_RECONNECT_FAILED",

  YOU_CREATED = "LOBBY_YOU_CREATED",
  YOU_JOINED = "LOBBY_YOU_JOINED",
  YOU_CARD_PICKS = "LOBBY_YOU_CARD_PICKS",
  YOU_NEW_CARD = "LOBBY_YOU_NEW_CARD",
  YOU_KICKED = "LOBBY_YOU_KICKED",

  UPDATE = "LOBBY_UPDATE",
  UPDATE_IMAGE = "LOBBY_UPDATE_IMAGE",
  UPDATE_CARD_GUESSED = "LOBBY_UPDATE_CARD_GUESSED",
  UPDATE_CARD_SKIPPED = "LOBBY_UPDATE_CARD_SKIPPED",

  MISSING = "LOBBY_MISSING",
}
