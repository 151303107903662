import { useCallback, useEffect, useMemo, useRef } from "react";
import { Message } from "../../../../interfaces/message";
import { useSessionStore } from "../../../../store/session.store";

export interface ChatListProps {
  className?: string;
}

const ChatEntry = ({ message }: { message: Message }) => {
  return (
    <li
      className={`${
        message.isSystemMessage ? "text-xs md:text-sm" : "text-sm md:text-md"
      }`}
    >
      <span
        className={`text-coal-200 font-mono text-xs md:text-sm mr-1 inline-block`}
      >
        {message.timestamp}
      </span>
      &nbsp;
      {!message.isSystemMessage && (
        <>
          <span style={{ color: message.color }}>{message.sender}</span>:{" "}
        </>
      )}
      <span className={`${message.isSystemMessage ? "italic opacity-75" : ""}`}>
        {message.message}
      </span>
    </li>
  );
};

const ChatList = ({ className = "" }: ChatListProps) => {
  const { session } = useSessionStore();
  const chatEndRef = useRef();
  const chat = useMemo(() => session?.chat, [session]);
  const chatLength = useMemo(() => chat?.length, [chat]);

  const scrollToBottom = useCallback((el: HTMLElement) => {
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(
    () => scrollToBottom(chatEndRef.current),
    [scrollToBottom, chatLength]
  );

  return (
    session && (
      <ul
        className={`relative flex-1 bg-black/10 dark:soso-bg rounded-md p-2 md:p-4 min-h-0 overflow-y-auto ${className}`}
      >
        {chat.map((m, i) => (
          <ChatEntry key={i} message={m} />
        ))}
        <span ref={chatEndRef} />
      </ul>
    )
  );
};

export default ChatList;
