import styles from "./squaresBackground.module.css";

const SquaresBackground = () => {
  return (
    <div
      className={`fixed inset-0 ${styles.area} bg-gray-300 dark:bg-black h-full w-full pointer-events-none`}
    >
      <ul className={`${styles.circles}`}>
        {new Array(4).fill({}).map((_, i) => (
          <li key={i}></li>
        ))}
      </ul>
    </div>
  );
};

export default SquaresBackground;
