export interface PageFrameProps {
  children: React.ReactNode;
}

const PageFrame = ({ children }: PageFrameProps) => {
  return (
    <div className="h-full overflow-y-auto overflow-x-hidden text-coal-900 dark:text-white flex justify-center pt-2 pb-12 px-2 md:py-8 items-center">
      {children}
    </div>
  );
};

export default PageFrame;
