import { FC } from "react";
import { useTranslation } from "react-i18next";
import Text, { TextVariant } from "../../../../../components/base/text";
import { GameMode } from "../../../../../interfaces/game";

export interface GameModeRulesProps {
  mode: GameMode;
}

const GameModeRules: FC<GameModeRulesProps> = ({ mode }) => {
  const { t } = useTranslation("game");
  return (
    <div className="soso-bg p-4 mb-2 md:mb-4 rounded-md text-left">
      <Text className="mb-2 text-center" variant={TextVariant.H1}>
        {t(`mode.${mode}`)}
      </Text>
      <div className="whitespace-pre-wrap leading-6">
        {t(`modeRules.${mode}`)}
      </div>
    </div>
  );
};

export default GameModeRules;
