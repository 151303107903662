import { RouterProvider } from "react-router-dom";
import Options from "./components/context/options/options";
import SquaresBackground from "./components/layout/squares-background/squaresBackground";
import "./locales/i18n";
import { router } from "./pages/lobby/router";

function App() {
  return (
    <>
      <Options />
      {/*<Audio />*/}
      <SquaresBackground />
      <main className="relative z-50 h-full">
        <RouterProvider router={router} />
      </main>
    </>
  );
}

export default App;
