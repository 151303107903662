import { useEffect, useMemo } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/base/button";
import Card from "../../components/base/card";
import UsernameInput from "../../components/context/username-input/usernameInput";
import PageFrame from "../../components/layout/page-frame/pageFrame";
import { useSocket } from "../../hooks/useSocket.hook";
import { useSessionStore } from "../../store/session.store";

export interface HomeFormInputs extends FieldValues {
  username: string;
}

const HomePage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HomeFormInputs>({
    defaultValues: {
      username: localStorage.getItem("soso_username") || "",
    },
  });
  const { t } = useTranslation("home");
  const { createLobby, joinLobby } = useSocket();
  const { session } = useSessionStore();

  const [searchParams] = useSearchParams();
  const lobbyIdParam = searchParams.get("lobbyId");

  const onSubmit: SubmitHandler<HomeFormInputs> = ({ username }) => {
    localStorage.setItem("soso_username", username);
    if (lobbyIdParam) {
      joinLobby(lobbyIdParam, username);
      return;
    }

    createLobby(username);
  };

  const navigate = useNavigate();
  const sessionId = useMemo(() => session?.id, [session]);

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    navigate(`/lobby/${sessionId}`);
  }, [navigate, sessionId]);

  return (
    <PageFrame>
      <Card className="max-w-[400px] w-full h-max px-12 py-8">
        <img
          src="/logo.png"
          alt="Name It! Logo"
          className="w-20 bg-coal-600 p-2 rounded-md dark:bg-transparent mx-auto"
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <UsernameInput
            register={register}
            error={errors["username"]}
            required
          />

          <Button type="submit" className="mt-6 w-full">
            {lobbyIdParam ? t("action.joinLobby") : t("action.createLobby")}
          </Button>
        </form>
      </Card>
    </PageFrame>
  );
};

export default HomePage;
