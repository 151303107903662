import { Game } from "./game";
import { Message } from "./message";
import { Player } from "./player";

export enum LobbyState {
  LOBBY = "LOBBY",
  PICK = "PICK",
  GAME = "GAME",
  RESULTS = "RESULTS",
}

export interface Session {
  id: string;
  players: Player[];
  chat: Message[];
  owner: Player;
  game: Game;
  state: LobbyState;
}
