import Chat from "../../../../components/context/chat/chat";
import {
  RenderWidth,
  useRenderWidth,
} from "../../../../hooks/useRenderWidth.hook";
import { useSessionStore } from "../../../../store/session.store";
import GameHeader from "./game-header/GameHeader";
import GameScore from "./game-score/GameScore";
import GameStage from "./game-stage/gameStage";
import GameState from "./game-state/GameState";
import GameTeams from "./game-teams/gameTeams";

const GameSm = () => {
  const { session } = useSessionStore();

  return (
    <div className={"flex flex-col md:hidden h-full w-full gap-2"}>
      <GameHeader className="flex-initial" />
      <div className={"flex-1 flex-shrink-0 min-h-0"}>
        <GameStage />
      </div>

      {/* <GameScore game={session.game} /> */}
      {/* <GameTeams /> */}

      <Chat className="w-full flex-initial flex-shrink h-32 max-h-[30%]" />
    </div>
  );
};

const GameMd = () => {
  const { session } = useSessionStore();

  return (
    <div className={"hidden md:flex h-full w-full gap-4"}>
      <div className="flex-2 min-w-0 flex flex-col gap-4">
        <div className="flex-2 min-h-0 soso-bg">
          <GameStage />
        </div>

        <div className="flex gap-4 flex-1 min-h-0">
          <div className="flex-1 min-w-0 soso-bg p-4 overflow-y-auto">
            <GameScore game={session.game} />
          </div>
          <div className="flex-1 min-w-0 soso-bg p-4 overflow-y-hidden">
            <GameTeams />
          </div>
          <div className="flex-1 min-w-0 soso-bg overflow-y-auto">
            <GameState />
          </div>
        </div>
      </div>
      <Chat className="flex-grow-0 flex-shrink-1 w-96" />
    </div>
  );
};

const Game = () => {
  const renderWidth = useRenderWidth();

  return (
    <>
      {renderWidth === RenderWidth.SM && <GameSm />}
      {renderWidth === RenderWidth.MD && <GameMd />}
    </>
  );
};

export default Game;
