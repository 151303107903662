export enum NotificationType {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface Notification {
  message: string;
  description?: string;
  type: NotificationType;
  timeout?: number;
  id?: number;
}
