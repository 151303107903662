import { useMemo, useState } from "react";
import Dialog from "../../../../components/layout/dialog/dialog";
import { GameMode } from "../../../../interfaces/game";
import { useSessionStore } from "../../../../store/session.store";
import GameModeRules from "../../states/game-state/game-mode-rules/GameModeRules";
import GameConfigMode from "../game-config-mode/gameConfigMode";

const GameConfigModes = () => {
  const { session } = useSessionStore();
  const game = useMemo(() => session?.game, [session]);
  const [modeRules, setModeRules] = useState<GameMode>(null);

  return (
    <div className="grid grid-cols-3 gap-2">
      {game.modes.map((m, i) => (
        <GameConfigMode
          className="cursor-pointer"
          key={m}
          mode={m}
          onClick={() => setModeRules(m)}
        />
      ))}
      {modeRules && (
        <Dialog onClose={() => setModeRules(null)}>
          <div className="w-full max-w-[480px] text-center">
            <GameModeRules mode={modeRules} />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default GameConfigModes;
