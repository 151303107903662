import { BaseSyntheticEvent, useCallback, useMemo } from "react";
import { FormProvider, UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Chat from "../../../../components/context/chat/chat";
import { useSessionProps } from "../../../../hooks/useSessionProps.hook";
import { useSocket } from "../../../../hooks/useSocket.hook";
import { Card } from "../../../../interfaces/card";
import { useSessionStore } from "../../../../store/session.store";
import PickStaples from "./pick-staples/pickStaples";
import PickSubmitButton from "./pick-submit-button/pickSubmitButton";
import PickSwipe from "./pick-swipe/pickSwipe";

export type PickableCard = Card & { selected: boolean };

export interface PickFormInputs {
  cardPicks: PickableCard[];
}

interface PickInternalProps {
  instructionText: string;
  neededPickCount: number;
  pickedCardCount: number;
  morePicksNeeded: boolean;
  cardPicks: PickableCard[];
  methods: UseFormReturn<PickFormInputs>;
  handleSubmit: (evt: BaseSyntheticEvent) => void;
}

const PickSm = ({
  handleSubmit,
  instructionText,
  morePicksNeeded,
  neededPickCount,
  pickedCardCount,
  methods,
  cardPicks,
}: PickInternalProps) => {
  return (
    <div className={`flex justify-center items-center w-full h-full md:hidden`}>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit}
          className="flex-2 flex flex-col h-full overflow-hidden relative gap-4"
        >
          <div className="text-md h-16 flex items-center justify-center text-center">
            {instructionText}
          </div>
          <PickSwipe morePicksNeeded={morePicksNeeded} cards={cardPicks} />
          <PickSubmitButton
            className="w-full"
            morePicksNeeded={morePicksNeeded}
            neededPickCount={neededPickCount}
            pickedCardCount={pickedCardCount}
          />
        </form>
      </FormProvider>
    </div>
  );
};

const PickMd = ({
  handleSubmit,
  instructionText,
  morePicksNeeded,
  neededPickCount,
  pickedCardCount,
  methods,
}: PickInternalProps) => {
  const { me } = useSessionProps();

  return (
    <div className="hidden md:block w-full h-full">
      {me.hasPicked ? (
        <div className="flex h-full items-center justify-center">
          {instructionText}
        </div>
      ) : (
        <div className="flex gap-4 h-full">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit}
              className="flex-2 flex flex-col h-full overflow-hidden relative gap-4"
            >
              <PickStaples
                morePicksNeeded={morePicksNeeded}
                neededPickCount={neededPickCount}
                instructionText={instructionText}
              />
            </form>
            <Chat className="flex-1" />
          </FormProvider>
        </div>
      )}
    </div>
  );
};

const Pick = () => {
  const { t } = useTranslation("pick");
  const { me } = useSessionProps();
  const { session, cardChoices } = useSessionStore();
  const { submitPicks } = useSocket();

  const methods = useForm<PickFormInputs>({
    defaultValues: {
      cardPicks: cardChoices,
    },
  });
  const { watch, handleSubmit } = methods;
  const allWatch = watch();

  const neededPickCount = useMemo(
    () => session.game.deckConfig.cardsPerPlayer,
    [session.game.deckConfig.cardsPerPlayer]
  );
  const pickedCardCount = useMemo(
    () => allWatch.cardPicks.filter((c) => c.selected).length,
    [allWatch]
  );
  const morePicksNeeded = useMemo(
    () => pickedCardCount < neededPickCount,
    [pickedCardCount, neededPickCount]
  );

  const instructionText = useMemo(() => {
    if (me.hasPicked) {
      return t("instructions.done");
    }

    if (pickedCardCount === neededPickCount) {
      return t("instructions.submit");
    }

    return t("instructions.pick", {
      toBePicked: neededPickCount,
    });
  }, [pickedCardCount, neededPickCount, me, t]);

  const onSubmit = useCallback(
    (evt: BaseSyntheticEvent) => {
      return handleSubmit((values) => {
        const pickIndexes: number[] = [];
        values.cardPicks.forEach((c, i) => {
          if (c.selected) {
            pickIndexes.push(i);
          }
        });
        submitPicks(pickIndexes);
      })(evt);
    },
    [handleSubmit, submitPicks]
  );

  return (
    <>
      {me.hasPicked && (
        <div className="flex h-full items-center justify-center text-center">
          {instructionText}
        </div>
      )}
      {!me.hasPicked && (
        <>
          <PickSm
            handleSubmit={onSubmit}
            instructionText={instructionText}
            morePicksNeeded={morePicksNeeded}
            neededPickCount={neededPickCount}
            pickedCardCount={pickedCardCount}
            methods={methods}
            cardPicks={allWatch.cardPicks}
          />
          <PickMd
            handleSubmit={onSubmit}
            instructionText={instructionText}
            morePicksNeeded={morePicksNeeded}
            neededPickCount={neededPickCount}
            pickedCardCount={pickedCardCount}
            methods={methods}
            cardPicks={allWatch.cardPicks}
          />
        </>
      )}
    </>
  );
};

export default Pick;
