import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { RiMoonClearFill, RiSettings2Fill, RiSunFill } from "react-icons/ri";
import {
  TbDeviceSpeaker,
  TbDeviceSpeakerOff,
  TbMusic,
  TbMusicOff,
} from "react-icons/tb";
import useSound from "use-sound";
import { useOptionsStore } from "../../../store/options.store";
import Button, { ButtonLevel } from "../../base/button";

const Options = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [theme, setTheme] = useState<"dark" | "light">(
    localStorage["soso_theme"]
  );
  console.log(theme);
  const {
    musicOn,
    musicVolume,
    effectsOn,
    effectsVolume,
    setMusicOn,
    setMusicVolume,
    setEffectsOn,
    setEffectsVolume,
  } = useOptionsStore();

  const [playBg, { stop: stopBg }] = useSound("/background-music.mp3", {
    loop: true,
    volume: musicVolume,
  });

  const [playEffects] = useSound("/card-skip.wav", {
    volume: effectsVolume,
  });

  const adjustMusicVolume = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const value = +evt.target.value / 100;
      setMusicVolume(value);
    },
    [setMusicVolume]
  );

  const adjustSoundEffectsVolume = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const value = +evt.target.value / 100;
      setEffectsVolume(value);
      playEffects();
    },
    [playEffects, setEffectsVolume]
  );

  useEffect(() => {
    if (musicOn) {
      playBg();
    } else {
      stopBg();
    }

    return () => stopBg();
  }, [musicOn, playBg, stopBg]);

  const adjustTheme = useCallback(
    (newTheme: "light" | "dark") => {
      if (theme === newTheme) {
        return;
      }

      setTheme(newTheme);
      localStorage["soso_theme"] = newTheme;

      if (newTheme === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
    [theme]
  );

  return (
    <div className="fixed text-white" style={{ zIndex: 10000 }}>
      <button
        className="fixed bottom-4 right-4"
        onClick={() => setDialogOpen(true)}
      >
        <RiSettings2Fill size={20} />
      </button>
      {dialogOpen && (
        <div className="fixed inset-0 bg-black/80">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col bg-white/20 backdrop-blur-sm py-4 px-12 rounded-md">
            <div className="text-center mb-3 text-xl font-bold">Settings</div>
            <div className="flex gap-4 mb-2">
              <button className="flex-initial p-1">
                {!musicOn ? (
                  <TbMusicOff size={20} onClick={() => setMusicOn(true)} />
                ) : (
                  <TbMusic size={20} onClick={() => setMusicOn(false)} />
                )}
              </button>
              <input
                className="flex-1"
                type="range"
                min="0"
                max="30"
                defaultValue={musicVolume * 100}
                onChange={adjustMusicVolume}
              />
            </div>
            <div className="flex gap-4 mb-2">
              <button className="flex-initial p-1">
                {!effectsOn ? (
                  <TbDeviceSpeakerOff
                    size={20}
                    onClick={() => setEffectsOn(true)}
                  />
                ) : (
                  <TbDeviceSpeaker
                    size={20}
                    onClick={() => setEffectsOn(false)}
                  />
                )}
              </button>
              <input
                className="flex-1"
                type="range"
                min="0"
                max="100"
                defaultValue={effectsVolume * 100}
                onInput={adjustSoundEffectsVolume}
              />
            </div>
            <div className="flex gap-1 mb-2" style={{ display: "none" }}>
              <button
                className={`flex-1 flex justify-center ${
                  theme === "light" ? "bg-cyan-700" : "bg-white/10"
                } p-2 rounded-l-full`}
                onClick={() => adjustTheme("light")}
              >
                <RiSunFill size={20} />
              </button>
              <button
                className={`flex-1 flex justify-center ${
                  theme === "dark" ? "bg-cyan-700" : "bg-white/10"
                } p-2 rounded-r-full`}
                onClick={() => adjustTheme("dark")}
              >
                <RiMoonClearFill size={20} />
              </button>
            </div>
            <div>
              <Button
                onClick={() => setDialogOpen(false)}
                className="w-full mt-4 py-1"
                level={ButtonLevel.PRIMARY}
              >
                Fertig
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Options;
